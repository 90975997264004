<template>

  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
  >
    <b-card
      no-body
      class="yxztCard"
      :action-refresh="true"
    >
      <div class="d-flex flex-column justify-content-between ml-2 mr-2 mb-50 mt-2">
        <div>
          <div class="d-flex justify-content-between">
            <span class="text-secondary">{{ property.name }}</span>
            <div class="d-flex">
              <feather-icon
                v-if="!isReadOnly"
                v-b-tooltip.hover.top="'设置设备属性值'"
                icon="Edit3Icon"
                size="16"
                class="text-primary mr-50 x-link"
                @click="setPropertyValue"
              />
              <feather-icon
                v-b-tooltip.hover.top="'获取设备属性值'"
                icon="RefreshCwIcon"
                size="16"
                class="text-primary ml-50 mr-50 x-link"
                @click="getPropertyValue"
              />
              <feather-icon
                v-if="showChart"
                v-b-tooltip.hover.top="'属性值详情与分析'"
                icon="TrendingUpIcon"
                size="16"
                class="text-primary ml-50 x-link"
                @click="showHistory = !showHistory"
              />
              <feather-icon
                v-if="property.valueType.type === $x.datatype.GeoPoint"
                v-b-tooltip.hover.top="'位置详情分析'"
                icon="MapPinIcon"
                size="16"
                class="text-primary ml-50 x-link"
                @click="showHistory = !showHistory"
              />
            </div>
          </div>
          <h1
            v-if="propertyValue || valueList[0].data.length > 0"
            class="mt-50 font-weight-bolder x-text-cut"
            v-b-tooltip.hover="propertyValue"
          >
            {{ propertyValue }}
          </h1>
          <h1
            v-else
            class="mb-1 mt-50 font-weight-bolder"
          >
            /
          </h1>
        </div>
        <vue-apex-charts
          v-if="showChart"
          type="area"
          height="100"
          width="100%"
          :options="chartOptionsComputed"
          :series="chartDatas"
        />
      </div>
      <property-value-form
        :show.sync="showSetPropertyForm"
        :propertys="[property]"
        @enter="setPropertyValueHandle"
      />
      <b-modal
        id="history-modal"
        :visible="showHistory"
        size="xl"
        title-class="x-text-bold"
        body-class="x-scrollbar pl-4 pr-4"
        cancel-variant="flat-secondary"
        ok-title=""
        title="历史数据"
        centered
        scrollable
        hide-footer
        @hidden="hideHistoryModal"
      >
        <device-data-history
          :device="device"
          :property="property"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, VBTooltip, BOverlay, BModal } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
  setPropertyValue, getPropertyValue,
} from '@/api/device-manage/device-list'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from '@core/components/statistics-cards/chartOptions'
import PropertyValueForm from '../things-model/PropertyValueForm.vue'
import DeviceDataHistory from './DeviceDataHistory.vue'

export default {
  components: {
    VueApexCharts,
    BCard,
    BOverlay,
    BModal,
    PropertyValueForm,
    DeviceDataHistory,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    propertyValue: {
      required: true,
    },
    property: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: 'primary',
    },
    valueList: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showLoading: false,
      showHistory: false,
      /**
       * 是否显示设置属性form
       */
      showSetPropertyForm: false,
      chartDatas: this.valueList,
    }
  },
  watch: {
    valueList: {
      handler(val) {
        this.chartDatas = [{
          name: val[0].name,
          data: val[0].data,
        }]
      },
      deep: true,
    },
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(areaChartOptions))
        options.theme.monochrome.color = $themeColors[this.color]
        return options
      }
      return this.chartOptions
    },
    isReadOnly() {
      return this.property.expands ? this.property.expands.readOnly === true : false
    },
    showChart() {
      return this.property.valueType.type !== this.$x.datatype.Boolean
        && this.property.valueType.type !== this.$x.datatype.Object
        && this.property.valueType.type !== this.$x.datatype.Array
        && this.property.valueType.type !== this.$x.datatype.File
        && this.property.valueType.type !== this.$x.datatype.GeoPoint
    },
  },
  methods: {
    setPropertyValue() {
      this.showSetPropertyForm = true
    },
    setPropertyValueHandle(formData, done) {
      setPropertyValue(this.device.id, formData).then(() => {
        done()
        this.$emit('done')
      }).catch(() => {
        // this.showLoading = false
      })
    },
    getPropertyValue() {
      this.showLoading = true
      getPropertyValue(this.device.id, this.property.id).then(() => {
        this.showLoading = false
        this.$emit('done')
      }).catch(() => {
        this.showLoading = false
      })
    },
    hideHistoryModal() {
      this.showHistory = false
    },
  },
}
</script>
<style lang="scss">
</style>
