<template>
  <x-form-validator ref="refFormObserver" v-if="isInitial">
    <x-form-feild label="事件标识" require>
      <!--        :state="errors.length > 0 ? false:null"-->
      <b-form-input
        v-model="formData.id"
        :disabled="isEdit"
        type="text"
        placeholder="请输入事件标识"
      />
    </x-form-feild>
    <x-form-feild label="事件名称" require>
      <b-form-input
        v-model="formData.name"
        placeholder="请输入事件名称"
      />
    </x-form-feild>
    <x-form-feild label="事件类型" tip="“信息”是设备上报的一般性通知，如完成某项任务等。“告警”和“故障”是设备运行过程中主动上报的突发或异常情况。" require>
      <b-form-radio-group
        v-model="formData.expands.level"
        :options="$x.datatype.eventLevels"
        value-field="value"
        text-field="label"
      />
    </x-form-feild>
    <x-form-feild label="输出参数">
      <v-select
        v-model="formData.valueType.type"
        :options="$x.datatype.output"
        label="label"
        :reduce="option => option.value"
        :get-option-label="option => option.value + ' (' + option.label +')'"
        placeholder="请选择输出参数">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <data-type-form class="w-100" :formData.sync="formData.valueType" :type="type" :units="units"></data-type-form>
    <x-form-feild label="描述">
      <b-form-textarea
        v-model="formData.description"
        placeholder="请输入描述"
      />
    </x-form-feild>
  </x-form-validator>
</template>

<script>
import { BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    XFormValidator,
    XFormFeild,
    BFormRadioGroup,
    BFormTextarea,
    vSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: undefined,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isInitial: false,
      isAdd: this.type === 'add',
      isEdit: this.type === 'edit',
      formData: {},
      // 是否显示对象参数表单
      showParamForm: false,
      // 对象参数 表单数据
      paramFormData: {},
      paramFormDataIndex: -1,
    }
  },
  watch: {
    value: {
      handler(val) {
        this.formData = this.dataInTransform(val)
        this.isInitial = true
      },
      deep: true,
      immediate: true,
    },
    type: {
      handler(val) {
        this.isAdd = val === 'add'
        this.isEdit = val === 'edit'
      },
      deep: true,
    },
  },
  methods: {
    // 传进来的数据与表单数据转换
    dataInTransform(val) {
      let temp
      if (!val) {
        temp = {}
      } else {
        temp = this._.cloneDeep(val)
      }
      if (!temp.expands) {
        temp.expands = {}
      }
      if (!temp.valueType) {
        temp.valueType = {}
      }
      this.$x.datatype.initPropertyValueTypeData(temp.valueType)
      return temp
    },
    getFormData() {
      let fdata = this._.cloneDeep(this.formData)
      if (fdata.output) {
        fdata.output = this.$x.datatype.getValueTypeData(fdata.output)
      }
      if (fdata.inputs) {
        let inputs = []
        fdata.inputs.forEach(item => {
          inputs.push(this.$x.datatype.getFormData(item))
        })
        fdata.inputs = inputs
      }
      return fdata
    },
    validate() {
      return this.$refs.refFormObserver.validate()
    },
    reset() {
      this.$refs.refFormObserver.reset()
      // 防止打开同一行 修改数据后没有保存 sidebar模式下重新刷新组件
      this.formData = this.dataInTransform(this.value)
    },
    toParamForm(property, index) {
      this.paramFormData = property
      this.paramFormDataIndex = index
      this.showParamForm = true
    },
    paramsEditDone(paramsData) {
      console.log(paramsData)
      this.formData.inputs[this.paramFormDataIndex] = paramsData
    },
  },
}
</script>

<style lang="scss">
</style>
