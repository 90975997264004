<template>
  <b-form class="m-2">
    <div
      v-for="(item,index) in columns"
      :key="index"
    >
      <div class="d-flex flex-row mb-50">
        <div class="x-indicator" />
        <div class="d-flex flex-row align-items-center ">
          <span class="font-medium-1 x-text-bold text-body ml-50 ">
            {{ item.name }}
          </span>
          <i
            v-if="!!item.description"
            v-b-tooltip.hover.top="item.description"
            class="el-icon-question ml-50 text-secondary text-lighten-5"></i>
        </div>
      </div>
      <b-row
        v-for="(property,pindex) in item.properties"
        :key="pindex"
      >
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">
                {{ property.property + (property.property !== property.name ? ('（' + property.name + '）') : '') }}
              </span>
              <i
                v-if="!!property.description"
                v-b-tooltip.hover.top="property.description"
                class="el-icon-question font-small-4 ml-50 text-secondary text-lighten-5"
              />
            </label>
            <b-form-input
              v-model="formData[property.property]"
              :placeholder="`请输入${property.name}`"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col
        cols="12"
        class="mt-1"
      >
        <b-button
          variant="primary"
          type="submit"
          block
          @click.prevent="submitForm"
        >
          确定
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  editConfig,
} from '@/api/device-manage/device-list'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, VBTooltip, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    deviceId: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    configs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {},
      columns: [],
    }
  },
  watch: {
    data: {
      handler(obj) {
        this.formData = this._.cloneDeep(obj)
      },
      deep: true,
    },

    configs() {
      this.columns = this.configs
    },
  },
  created() {
    console.log(this.data)
    this.formData = this._.cloneDeep(this.data)
    this.columns = this.configs
  },
  methods: {
    submitForm() {
      editConfig(this.deviceId, {
        configuration: this.formData,
      }).then(() => {
        this.$xtoast.success('编辑成功')
        this.$emit('editDone')
      })
    },
    hide() {
      this.$refs.refFormObserver.reset()
      this.formData = {}
      console.log(1111)
    },
  },
}
</script>

<style lang="scss">
  .x-require-indicator{
    margin-right: .2rem;
    color: red;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
</style>
