<template>
  <b-tabs
    vertical
    active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
    nav-wrapper-class="nav-vertical">
    <b-alert v-if="isProduct && showAlert" variant="warning" class="ml-2 mr-2 x-anim-fade-in" show>
      <x-popconfirm
        target="config-alert"
        content="确定要重新应用配置？"
        triggers="click"
        @enter="reconfig">
      </x-popconfirm>
      <div class="alert-body">
        <feather-icon icon="AlertTriangleIcon" size="18"></feather-icon>
        修改物模型请重新<span id="config-alert" class="ml-50 x-link">应用配置</span>
      </div>
    </b-alert>
    <b-tab
      active
      title="属性">
      <x-table
        ref="property"
        hide-search-bar
        no-page
        custom-form
        :options="commonOptions"
        @search="searchPropertyHandle"
        @rowAdd="rowPropertyAdd"
        @rowEdit="rowPropertyEdit"
        @rowDelete="rowPropertyDelete"
        @onFormHide="onPropertyFormHide">
        <template slot="valueType" slot-scope="{ data }">
          <b-badge variant="light-primary">{{ $x.datatype.outputMap[data.type] }}</b-badge>
        </template>
        <template slot="expands" slot-scope="{data}">
          <b-badge :variant="getExpandBadge('readonly', data)">{{ getExpandString('readonly', data) }}</b-badge>
        </template>
        <template slot="custom-form" slot-scope="{type, index}">
          <property-form ref="propertyForm" v-model="properties[index]" :type="type" :units="units"></property-form>
        </template>
      </x-table>
    </b-tab>
    <b-tab
      title="功能" lazy>
      <x-table
        ref="function"
        hide-search-bar
        no-page
        custom-form
        :options="functionOptions"
        @search="searchFunctionHandle"
        @rowAdd="rowFunctionAdd"
        @rowEdit="rowFunctionEdit"
        @rowDelete="rowFunctionDelete"
        @onFormHide="onFunctionFormHide">
        <template slot="async" slot-scope="{data}">
          <b-badge :variant="getExpandBadge('async', data)">{{ getExpandString('async', data) }}</b-badge>
        </template>
        <template slot="custom-form" slot-scope="{type, index, show}">
          <function-form v-if="show" ref="functionForm" v-model="functions[index]" :type="type" :units="units"/>
        </template>
      </x-table>
    </b-tab>
    <b-tab
      title="事件" lazy>
      <x-table
        ref="event"
        hide-search-bar
        no-page
        custom-form
        :options="eventsOptions"
        @search="searchEventHandle"
        @rowAdd="rowEventAdd"
        @rowEdit="rowEventEdit"
        @rowDelete="rowEventDelete"
        @onFormHide="onEventFormHide">
        <template slot="valueType" slot-scope="{ data }">
          <b-badge variant="light-primary">{{ $x.datatype.outputMap[data.type] }}</b-badge>
        </template>
        <template slot="expands" slot-scope="{data}"><b-badge :variant="getExpandBadge('level', data.level)">{{ getExpandString('level', data.level) }}</b-badge>
        </template>
        <template slot="custom-form" slot-scope="{type, index}">
          <event-form ref="eventForm" v-model="events[index]" :type="type" :units="units"/>
        </template>
      </x-table>
    </b-tab>
    <b-tab
      title="标签" lazy>
      <x-table
        ref="tag"
        hide-search-bar
        no-page
        custom-form
        :options="commonOptions"
        @search="searchTagHandle"
        @rowAdd="rowTagAdd"
        @rowEdit="rowTagEdit"
        @rowDelete="rowTagDelete"
        @onFormHide="onTagFormHide">
        <template slot="valueType" slot-scope="{ data }">
          <b-badge variant="light-primary">{{ $x.datatype.outputMap[data.type] }}</b-badge>
        </template>
        <template slot="expands" slot-scope="{data}">
          <b-badge :variant="getExpandBadge('readonly', data)">{{ getExpandString('readonly', data) }}</b-badge>
        </template>
        <template slot="custom-form" slot-scope="{type, index}">
          <tag-form ref="tagForm" v-model="tags[index]" :type="type" :units="units"/>
        </template>
      </x-table>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BBadge, BAlert } from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
import { edit, deploy } from '@/api/device-manage/product-list'
import { editMetadata } from '@/api/device-manage/device-list'
import { mapGetters } from 'vuex'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import PropertyForm from './PropertyForm.vue'
import FunctionForm from './FunctionForm.vue'
import EventForm from './EventForm.vue'
import TagForm from './TagForm.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,
    PropertyForm,
    FunctionForm,
    EventForm,
    TagForm,
    XTable,
    BAlert,
    XPopconfirm,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否为产品物模型
     */
    isProduct: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      'units',
    ]),
  },
  data() {
    return {
      data: {},
      properties: [],
      functions: [],
      events: [],
      tags: [],
      showAlert: false,
      commonOptions: {
        exportBtn: false,
        printBtn: false,
        formType: 'sidebar',
        actions: [{ action: 'edit', name: '编辑', icon: 'EditIcon' }, { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        columns: [{
          label: '标识',
          prop: 'id',
          searchShow: false,
          sortable: true,
        }, {
          label: '名称',
          prop: 'name',
          searchShow: false,
          sortable: true,
        }, {
          label: '数据类型',
          prop: 'valueType',
          searchShow: false,
          sortable: true,
        }, {
          label: '是否只读',
          labelShow: true,
          prop: 'expands',
          searchShow: false,
          rowShow: true,
        }, {
          label: '描述',
          labelShow: true,
          type: 'textarea',
          prop: 'description',
          searchShow: false,
        },
        ],
      },
      functionOptions: {
        formType: 'sidebar',
        exportBtn: false,
        printBtn: false,
        actions: [{ action: 'edit', name: '编辑', icon: 'EditIcon' }, { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        columns: [{
          label: '标识',
          prop: 'id',
          searchShow: false,
          sortable: true,
        }, {
          label: '名称',
          prop: 'name',
          searchShow: false,
          sortable: true,
        }, {
          label: '是否异步',
          prop: 'async',
          searchShow: false,
          sortable: true,
        }, {
          label: '描述',
          labelShow: true,
          type: 'textarea',
          prop: 'description',
          searchShow: false,
        },
        ],
      },
      eventsOptions: {
        formType: 'sidebar',
        exportBtn: false,
        printBtn: false,
        actions: [{ action: 'edit', name: '编辑', icon: 'EditIcon' }, { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        columns: [{
          label: '标识',
          prop: 'id',
          searchShow: false,
          sortable: true,
        }, {
          label: '名称',
          prop: 'name',
          searchShow: false,
          sortable: true,
        }, {
          label: '事件类型',
          prop: 'expands',
          searchShow: false,
          sortable: true,
        }, {
          label: '输出参数',
          prop: 'valueType',
          searchShow: false,
          sortable: true,
        }, {
          label: '描述',
          labelShow: true,
          type: 'textarea',
          prop: 'description',
          searchShow: false,
        },
        ],
      },
    }
  },
  watch: {
    value: {
      handler() {
        this.load()
        if (this.$refs.property) {
          this.$refs.property.__searchHandle()
        }
        if (this.$refs.function) {
          this.$refs.function.__searchHandle()
        }
        if (this.$refs.event) {
          this.$refs.event.__searchHandle()
        }
        if (this.$refs.tag) {
          this.$refs.tag.__searchHandle()
        }
      },
      deep: true,
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.data = this._.cloneDeep(this.value)
      if (this.data.metadata) {
        const { events, functions, properties, tags } = JSON.parse(this.data.metadata)
        this.events = events || []
        this.functions = functions || []
        this.properties = properties || []
        this.tags = tags || []
      } else {
        this.events = []
        this.functions = []
        this.properties = []
        this.tags = []
      }
    },
    getExpandBadge(feild, value) {
      switch (feild) {
        case 'readonly':
        case 'async': {
          return value ? 'light-warning' : 'light-primary'
        }
        case 'level': {
          switch (value) {
            case 'warn':
              return 'light-warning'
            case 'urgent':
              return 'light-danger'
            case 'ordinary':
            default:
              return 'light-primary'
          }
        }
        default:
          return 'light-primary'
      }
    },
    getExpandString(feild, value) {
      switch (feild) {
        case 'readonly':
        case 'async': {
          return (value === true || value === 'true') ? '是' : '否'
        }
        case 'level': {
          switch (value) {
            case 'ordinary':
              return '普通'
            case 'warn':
              return '警告'
            case 'urgent':
              return '严重'
            default:
              return ''
          }
        }
        default:
          return ''
      }
    },
    searchPropertyHandle(page, params, done) {
      done(this.properties, this.properties.size)
    },
    rowPropertyAdd(data, done) {
      this.$refs.propertyForm.validate().then(success => {
        if (success) {
          data = this.$refs.propertyForm.getFormData()
          for (let prop of this.properties) {
            if (data.id === prop.id) {
              this.$xtoast.error('该标识已经存在！')
              return
            }
          }
          let props = this._.cloneDeep(this.properties)
          props.push(data)
          let thingsModel = {
            properties: props,
            functions: this.functions,
            events: this.events,
            tags: this.tags,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.properties.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.properties.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowPropertyEdit(data, done, index) {
      this.$refs.propertyForm.validate().then(success => {
        if (success) {
          data = this.$refs.propertyForm.getFormData()
          let props = this._.cloneDeep(this.properties)
          props[index] = data
          let thingsModel = {
            properties: props,
            functions: this.functions,
            events: this.events,
            tags: this.tags,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.properties = props
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              // 刷新表格 无需调用接口
              this.properties = props
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowPropertyDelete(data, done, index) {
      let props = this._.cloneDeep(this.properties)
      props.splice(index, 1)
      let thingsModel = {
        properties: props,
        functions: this.functions,
        events: this.events,
        tags: this.tags,
      }
      if (this.isProduct) {
        this.data.metadata = JSON.stringify(thingsModel)
        edit(this.data).then(() => {
          this.properties.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      } else {
        editMetadata(this.value.id, thingsModel).then(() => {
          this.properties.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      }
    },
    onPropertyFormHide() {
      if (this.$refs.propertyForm) {
        this.$refs.propertyForm.reset()
      }
    },
    searchFunctionHandle(page, params, done) {
      done(this.functions, this.functions.size)
    },
    rowFunctionAdd(data, done) {
      this.$refs.functionForm.validate().then(success => {
        if (success) {
          data = this.$refs.functionForm.getFormData()
          for (let prop of this.functions) {
            if (data.id === prop.id) {
              this.$xtoast.error('该标识已经存在！')
              return
            }
          }
          let funcs = this._.cloneDeep(this.functions)
          funcs.push(data)
          let thingsModel = {
            properties: this.properties,
            functions: funcs,
            events: this.events,
            tags: this.tags,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.functions.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.functions.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowFunctionEdit(data, done, index) {
      this.$refs.functionForm.validate().then(success => {
        if (success) {
          data = this.$refs.functionForm.getFormData()
          let funcs = this._.cloneDeep(this.functions)
          funcs[index] = data
          let thingsModel = {
            properties: this.properties,
            functions: funcs,
            events: this.events,
            tags: this.tags,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.functions = funcs
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.functions = funcs
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowFunctionDelete(data, done, index) {
      let funcs = this._.cloneDeep(this.functions)
      funcs.splice(index, 1)
      let thingsModel = {
        properties: this.properties,
        functions: funcs,
        events: this.events,
        tags: this.tags,
      }
      if (this.isProduct) {
        this.data.metadata = JSON.stringify(thingsModel)
        edit(this.data).then(() => {
          this.functions.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      } else {
        editMetadata(this.value.id, thingsModel).then(() => {
          this.functions.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      }
    },
    onFunctionFormHide() {
      if (this.$refs.functionForm) {
        this.$refs.functionForm.reset()
      }
    },
    searchEventHandle(page, params, done) {
      done(this.events, this.events.size)
    },
    rowEventAdd(data, done) {
      this.$refs.eventForm.validate().then(success => {
        if (success) {
          data = this.$refs.eventForm.getFormData()
          for (let prop of this.events) {
            if (data.id === prop.id) {
              this.$xtoast.error('该标识已经存在！')
              return
            }
          }
          let eves = this._.cloneDeep(this.events)
          eves.push(data)
          let thingsModel = {
            properties: this.properties,
            functions: this.functions,
            events: eves,
            tags: this.tags,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.events.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.events.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowEventEdit(data, done, index) {
      this.$refs.eventForm.validate().then(success => {
        if (success) {
          data = this.$refs.eventForm.getFormData()
          let eves = this._.cloneDeep(this.events)
          eves[index] = data
          let thingsModel = {
            properties: this.properties,
            functions: this.functions,
            events: eves,
            tags: this.tags,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.events = eves
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.events = eves
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowEventDelete(data, done, index) {
      let eves = this._.cloneDeep(this.events)
      eves.splice(index, 1)
      let thingsModel = {
        properties: this.properties,
        functions: this.functions,
        events: eves,
        tags: this.tags,
      }
      if (this.isProduct) {
        this.data.metadata = JSON.stringify(thingsModel)
        edit(this.data).then(() => {
          this.events.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      } else {
        editMetadata(this.value.id, thingsModel).then(() => {
          this.events.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      }
    },
    onEventFormHide() {
      if (this.$refs.eventForm) {
        this.$refs.eventForm.reset()
      }
    },
    searchTagHandle(page, params, done) {
      done(this.tags, this.tags.size)
    },
    rowTagAdd(data, done) {
      this.$refs.tagForm.validate().then(success => {
        if (success) {
          data = this.$refs.tagForm.getFormData()
          for (let prop of this.tags) {
            if (data.id === prop.id) {
              this.$xtoast.error('该标识已经存在！')
              return
            }
          }
          let ts = this._.cloneDeep(this.tags)
          ts.push(data)
          let thingsModel = {
            properties: this.properties,
            functions: this.functions,
            events: this.events,
            tags: ts,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.tags.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.tags.push(data)
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowTagEdit(data, done, index) {
      this.$refs.tagForm.validate().then(success => {
        if (success) {
          data = this.$refs.tagForm.getFormData()
          let ts = this._.cloneDeep(this.tags)
          ts[index] = data
          let thingsModel = {
            properties: this.properties,
            functions: this.functions,
            events: this.events,
            tags: ts,
          }
          if (this.isProduct) {
            this.data.metadata = JSON.stringify(thingsModel)
            edit(this.data).then(() => {
              this.tags = ts
              done()
              this.showAlert = true
              this.$emit('done')
            })
          } else {
            editMetadata(this.value.id, thingsModel).then(() => {
              this.tags = ts
              done()
              this.showAlert = true
              this.$emit('done')
            })
          }
        }
      })
    },
    rowTagDelete(data, done, index) {
      let ts = this._.cloneDeep(this.tags)
      ts.splice(index, 1)
      let thingsModel = {
        properties: this.properties,
        functions: this.functions,
        events: this.events,
        tags: ts,
      }
      if (this.isProduct) {
        this.data.metadata = JSON.stringify(thingsModel)
        edit(this.data).then(() => {
          this.tags.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      } else {
        editMetadata(this.value.id, thingsModel).then(() => {
          this.tags.splice(index, 1)
          done()
          this.showAlert = true
          this.$emit('done')
        })
      }
    },
    onTagFormHide() {
      if (this.$refs.tagForm) {
        this.$refs.tagForm.reset()
      }
    },
    reconfig() {
      deploy(this.data.id).then(() => {
        this.$xtoast.success('配置成功')
        this.showAlert = false
        this.$emit('done')
      })
    },
  },
}
</script>

<style lang="scss">
</style>
