<template>
  <div class="x-map-travel">
    <div class="position-absolute x-bottom-0 x-h-center zindex-4 card pl-2 pr-2 pt-1 pb-1 d-flex flex-row">
      <div class="btn-flat-primary btn mr-1" @click="locationStart"><feather-icon icon="NavigationIcon" class="mr-50"></feather-icon>起点</div>
      <div class="btn-flat-primary btn mr-1" @click="locationEnd"><feather-icon icon="DiscIcon" class="mr-50"></feather-icon>终点</div>
      <div :class="play ? 'disabled btn-flat-secondary' : 'btn-flat-primary'" class=" btn mr-1" @click="play = true"><feather-icon icon="PlayIcon" class="mr-50"></feather-icon>播放</div>
      <div :class="play ? 'btn-flat-warning' : 'disabled btn-flat-secondary'" class="btn"><feather-icon icon="PauseIcon" class="mr-50" @click="pause"></feather-icon>暂停</div>
      <!--<div :class="play ? 'btn-flat-danger' : 'disabled btn-flat-secondary'" class="btn"><feather-icon icon="XIcon" class="mr-50" @click="stop"></feather-icon>停止</div>-->
    </div>
    <baidu-map class="x-map-travel__map" style="width: 100%;height: 80vh"
               :mapStyle="mapStyle"
               @ready="mapReady"
               :scroll-wheel-zoom="true" :center="center" :zoom="14">
      <!--<bm-driving start="天安门" end="百度大厦" @searchcomplete="handleSearchComplete" :panel="false" :autoViewport="true"></bm-driving>-->
      <bm-marker v-if="start" :position="start" :icon="startIcon" >
      </bm-marker>
      <!--<bm-marker v-if="start && !play" :position="start" :icon="carIcon">-->
      <!--</bm-marker>-->
      <!--animation="BMAP_ANIMATION_BOUNCE"-->
      <bm-marker v-if="end" :position="end" :icon="endIcon">
      </bm-marker>
      <bm-polyline :path="data" stroke-color="red" :stroke-opacity="0.6" :stroke-weight="6" ></bm-polyline>
      <bml-lushu
        ref="lushu"
        @stop="reset"
        :path="data"
        :icon="icon"
        :play="play"
        :speed="speed"
        :landmarkPois="landmarkPois"
        :autoView="autoView"
        :rotation="rotation">
      </bml-lushu>
    </baidu-map>
  </div>
</template>

<script>
import { BmlLushu } from 'vue-baidu-map'
import userAppConfig from '@core/app-config/useAppConfig'

function defalutIcon() {
  return {
    url: 'http://file.cloudxin.ltd/aiot/icon/car.png',
    size: { width: 26, height: 52 },
    opts: { anchor: { width: 13, height: 27 } },
  }
}

export default {
  name: 'MapTravel',
  components: {
    BmlLushu,
  },
  props: {
    // 路线数据 Array[{lng, lat}]
    data: {
      type: Array,
      default: () => [],
    },
    // Array[{lng, lat, html, pauseTime}] 要在覆盖物移动过程中显示的特殊点
    landmarkPois: {
      type: Array,
      default: () => [],
    },
    // 覆盖物图标
    icon: {
      type: Object,
      default: () => defalutIcon(),
    },
    speed: {
      type: Number,
      default: 1000,
    },
    // 是否自动调整路线视野
    autoView: {
      type: Boolean,
      default: false,
    },
    // 是否自动调整路线视野
    rotation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 主题
      skin: userAppConfig().skin,
      play: false,
      center: { lng: 116.404, lat: 39.915 },
      start: undefined,
      end: undefined,
      carIcon: {
        url: 'http://api.map.baidu.com/library/LuShu/1.2/examples/car.png',
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } },
      },
      startIcon: { url: 'http://file.cloudxin.ltd/aiot/icon/icon_start.svg', size: { width: 50, height: 50 } },
      endIcon: { url: 'http://file.cloudxin.ltd/aiot/icon/icon_end.svg', size: { width: 50, height: 50 } },
    }
  },
  computed: {
    mapStyle() {
      return this.skin.value === 'dark' ? {
        style: 'midnight',
      } : undefined
    },
  },
  watch: {
    data() {
      this.setCenter()
    },
  },
  created() {
    this.setPosition()
  },
  methods: {
    mapReady() {
    },
    setPosition() {
      if (this.data && this.data.length > 0) {
        this.center = { lng: this.data[0].lng, lat: this.data[0].lat }
        this.start = { lng: this.data[0].lng, lat: this.data[0].lat }
        this.end = { lng: this.data[this.data.length - 1].lng, lat: this.data[this.data.length - 1].lat }
      } else {
        this.center = { lng: 116.404, lat: 39.915 }
        this.start = undefined
        this.end = undefined
      }
    },
    reset() {
      this.play = false
    },
    locationStart() {
      if (this.data && this.data.length > 0) {
        this.center.lng = this.data[0].lng
        this.center.lat = this.data[0].lat
      }
    },
    locationEnd() {
      if (this.data && this.data.length > 0) {
        this.center.lng = this.data[this.data.length - 1].lng
        this.center.lat = this.data[this.data.length - 1].lat
      }
    },
    pause() {
      this.play = false
    },
    stop() {
      this.$refs.lushu.stop()
    },
  },
}
</script>

<style lang="scss" scoped>
.x-map-travel {
  width: 100%;
  height: 100%;
  &__map {
  }
}
</style>
