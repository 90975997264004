<template>
  <div>
    <x-table
      :options="options"
      @search="searchHandle"
    >
      <template slot="deviceId">
        {{device.name}}
      </template>
      <template slot="productId">
        {{device.productName}}
      </template>
      <template slot="custom-form" slot-scope="{row}">
        <device-log-detail
          :data="row"
          :device="device"
          :orgName="orgName"
          :units="units"
        />
      </template>
    </x-table>
  </div>
</template>
<script>
import {
  queryLogByDeviceId,
} from '@/api/device-manage/device-list'
import { queryUnits } from '@/api/device-access/protocol'
import XTable from '@core/components/cx/table/XTable.vue'
import DeviceLogDetail from './DeviceLogDetail.vue'

const logType = [
  {
    text: '属性上报',
    value: 'reportProperty',
  },
  {
    text: '事件上报',
    value: 'event',
  },
  {
    text: '调用功能',
    value: 'functionInvoke',
  },
  {
    text: '读取属性',
    value: 'readProperty',
  },
  {
    text: '修改属性',
    value: 'writeProperty',
  },
  {
    text: '修改属性回复',
    value: 'writePropertyReply',
  },
  {
    text: '读取属性回复',
    value: 'readPropertyReply',
  },
  // {
  //   text: '子设备消息',
  //   value: 'child'
  // },
  // {
  //   text: "子设备消息回复",
  //   value: "childReply"
  // },
  {
    text: '调用功能回复',
    value: 'functionReply',
  },
  {
    text: '设备注册',
    value: 'register',
  },
  {
    text: '设备注销',
    value: 'unregister',
  },
  // {
  //   text: "读取固件信息",
  //   value: "readFirmware"
  // },
  // {
  //   text: "读取固件信息回复",
  //   value: "readFirmwareReply"
  // },
  // {
  //   text: "上报固件信息",
  //   value: "reportFirmware"
  // },
  // {
  //   text: "拉取固件信息",
  //   value: "pullFirmware"
  // },
  // {
  //   text: "拉取固件信息回复",
  //   value: "pullFirmwareReply"
  // },
  // {
  //   text: "推送固件信息",
  //   value: "upgradeFirmware"
  // },
  // {
  //   text: "推送固件信息回复",
  //   value: "upgradeFirmwareReply"
  // },
  // {
  //   text: "固件更新进度",
  //   value: "upgradeFirmwareProgress"
  // },
  {
    text: '日志',
    value: 'log',
  },
  {
    text: '标签更新',
    value: 'tag',
  },
  {
    text: '离线',
    value: 'offline',
  },
  {
    text: '上线',
    value: 'online',
  },
  {
    text: '其它',
    value: 'other',
  },
  // {
  //   text: '透传',
  //   value: "direct"
  // },
  // {
  //   text: '应答',
  //   value: 'acknowledge'
  // },
  // {
  //   text: "上报物模型",
  //   value: "metadata"
  // },
  // {
  //   text: "状态检查",
  //   value: "stateCheck"
  // },
  // {
  //   text: "状态检查回复",
  //   value: "stateCheckReply"
  // },
  {
    text: '断开连接',
    value: 'disconnect',
  },
  {
    text: '断开连接回复',
    value: 'disconnectReply',
  },
  // {
  //   text: "上报数采数据",
  //   value: "reportCollectorData"
  // },
  // {
  //   text: "读取数采数据",
  //   value: "readCollectorData"
  // },
  // {
  //   text: "读取数采数据回复",
  //   value: "readCollectorDataReply"
  // },
  // {
  //   text: "修改数采数据",
  //   value: "writeCollectorData"
  // },
  // {
  //   text: "修改数采数据回复",
  //   value: "writeCollectorDataReply"
  // }
]
export default {
  components: {
    XTable,
    DeviceLogDetail,
  },
  directives: {
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    orgName: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      logContent: {},
      units: [],
      options: {
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' }],
        collapseExpandAll: true,
        formWidth: 'lg',
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        columns: [{
          label: '设备名称',
          labelShow: true,
          prop: 'deviceId',
          searchShow: false,
        }, {
          label: '产品名称',
          labelShow: true,
          prop: 'productId',
          searchShow: false,
        }, {
          label: '日志类型',
          labelShow: true,
          prop: 'type',
          type: 'select',
          // dictUrl: '/dictionary/device-log-type/items',
          dictData: logType,
          rowSelect: true,
          selectVariant: prop => this.$x.biz.getLogTypeTag(prop),
          props: {
            label: 'text',
            value: 'value',
          },
          searchShow: true,
          addDisable: true,
          sortable: true,
        }, {
          label: '时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          sortable: true,
          searchShow: true,
        }],
      },
    }
  },
  created() {
    queryUnits().then(resp => {
      if (!resp.data.result) {
        return
      }
      this.units = resp.data.result
    })
  },
  methods: {
    searchHandle(page, params, done) {
      queryLogByDeviceId(page.pageIndex - 1, page.pageSize, this.device.id, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
