var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{attrs:{"vertical":"","active-nav-item-class":"bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in","nav-wrapper-class":"nav-vertical"}},[(_vm.isProduct && _vm.showAlert)?_c('b-alert',{staticClass:"ml-2 mr-2 x-anim-fade-in",attrs:{"variant":"warning","show":""}},[_c('x-popconfirm',{attrs:{"target":"config-alert","content":"确定要重新应用配置？","triggers":"click"},on:{"enter":_vm.reconfig}}),_c('div',{staticClass:"alert-body"},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon","size":"18"}}),_vm._v(" 修改物模型请重新"),_c('span',{staticClass:"ml-50 x-link",attrs:{"id":"config-alert"}},[_vm._v("应用配置")])],1)],1):_vm._e(),_c('b-tab',{attrs:{"active":"","title":"属性"}},[_c('x-table',{ref:"property",attrs:{"hide-search-bar":"","no-page":"","custom-form":"","options":_vm.commonOptions},on:{"search":_vm.searchPropertyHandle,"rowAdd":_vm.rowPropertyAdd,"rowEdit":_vm.rowPropertyEdit,"rowDelete":_vm.rowPropertyDelete,"onFormHide":_vm.onPropertyFormHide},scopedSlots:_vm._u([{key:"valueType",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(_vm.$x.datatype.outputMap[data.type]))])]}},{key:"expands",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":_vm.getExpandBadge('readonly', data)}},[_vm._v(_vm._s(_vm.getExpandString('readonly', data)))])]}},{key:"custom-form",fn:function(ref){
var type = ref.type;
var index = ref.index;
return [_c('property-form',{ref:"propertyForm",attrs:{"type":type,"units":_vm.units},model:{value:(_vm.properties[index]),callback:function ($$v) {_vm.$set(_vm.properties, index, $$v)},expression:"properties[index]"}})]}}])})],1),_c('b-tab',{attrs:{"title":"功能","lazy":""}},[_c('x-table',{ref:"function",attrs:{"hide-search-bar":"","no-page":"","custom-form":"","options":_vm.functionOptions},on:{"search":_vm.searchFunctionHandle,"rowAdd":_vm.rowFunctionAdd,"rowEdit":_vm.rowFunctionEdit,"rowDelete":_vm.rowFunctionDelete,"onFormHide":_vm.onFunctionFormHide},scopedSlots:_vm._u([{key:"async",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":_vm.getExpandBadge('async', data)}},[_vm._v(_vm._s(_vm.getExpandString('async', data)))])]}},{key:"custom-form",fn:function(ref){
var type = ref.type;
var index = ref.index;
var show = ref.show;
return [(show)?_c('function-form',{ref:"functionForm",attrs:{"type":type,"units":_vm.units},model:{value:(_vm.functions[index]),callback:function ($$v) {_vm.$set(_vm.functions, index, $$v)},expression:"functions[index]"}}):_vm._e()]}}])})],1),_c('b-tab',{attrs:{"title":"事件","lazy":""}},[_c('x-table',{ref:"event",attrs:{"hide-search-bar":"","no-page":"","custom-form":"","options":_vm.eventsOptions},on:{"search":_vm.searchEventHandle,"rowAdd":_vm.rowEventAdd,"rowEdit":_vm.rowEventEdit,"rowDelete":_vm.rowEventDelete,"onFormHide":_vm.onEventFormHide},scopedSlots:_vm._u([{key:"valueType",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(_vm.$x.datatype.outputMap[data.type]))])]}},{key:"expands",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":_vm.getExpandBadge('level', data.level)}},[_vm._v(_vm._s(_vm.getExpandString('level', data.level)))])]}},{key:"custom-form",fn:function(ref){
var type = ref.type;
var index = ref.index;
return [_c('event-form',{ref:"eventForm",attrs:{"type":type,"units":_vm.units},model:{value:(_vm.events[index]),callback:function ($$v) {_vm.$set(_vm.events, index, $$v)},expression:"events[index]"}})]}}])})],1),_c('b-tab',{attrs:{"title":"标签","lazy":""}},[_c('x-table',{ref:"tag",attrs:{"hide-search-bar":"","no-page":"","custom-form":"","options":_vm.commonOptions},on:{"search":_vm.searchTagHandle,"rowAdd":_vm.rowTagAdd,"rowEdit":_vm.rowTagEdit,"rowDelete":_vm.rowTagDelete,"onFormHide":_vm.onTagFormHide},scopedSlots:_vm._u([{key:"valueType",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(_vm.$x.datatype.outputMap[data.type]))])]}},{key:"expands",fn:function(ref){
var data = ref.data;
return [_c('b-badge',{attrs:{"variant":_vm.getExpandBadge('readonly', data)}},[_vm._v(_vm._s(_vm.getExpandString('readonly', data)))])]}},{key:"custom-form",fn:function(ref){
var type = ref.type;
var index = ref.index;
return [_c('tag-form',{ref:"tagForm",attrs:{"type":type,"units":_vm.units},model:{value:(_vm.tags[index]),callback:function ($$v) {_vm.$set(_vm.tags, index, $$v)},expression:"tags[index]"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }