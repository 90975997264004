var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-table',{attrs:{"options":_vm.options},on:{"search":_vm.searchHandle},scopedSlots:_vm._u([{key:"fid",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(_vm.parseRowData(row, 'functionId')),expression:"parseRowData(row, 'functionId')",modifiers:{"hover":true}}],staticClass:"x-text-cut",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(_vm.parseRowData(row, 'functionId')))])]}},{key:"fname",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(_vm.getFunctionName(_vm.parseRowData(row, 'functionId'))),expression:"getFunctionName(parseRowData(row, 'functionId'))",modifiers:{"hover":true}}],staticClass:"x-text-cut",attrs:{"variant":"light-warning"}},[_vm._v(_vm._s(_vm.getFunctionName(_vm.parseRowData(row, 'functionId'))))])]}},{key:"input",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(JSON.stringify(_vm.parseRowData(row, 'inputs'))),expression:"JSON.stringify(parseRowData(row, 'inputs'))",modifiers:{"hover":true}}],staticClass:"x-text-cut"},[_vm._v(_vm._s(_vm.parseRowData(row, 'inputs')))])]}},{key:"output",fn:function(ref){
var row = ref.row;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",value:(JSON.stringify(_vm.parseRowData(row, 'outputs'))),expression:"JSON.stringify(parseRowData(row, 'outputs'))",modifiers:{"hover":true}}],staticClass:"x-text-cut"},[_vm._v(_vm._s(_vm.parseRowData(row, 'outputs')))])]}},{key:"custom-form",fn:function(ref){
var row = ref.row;
return [_c('device-log-detail',{attrs:{"data":row,"device":_vm.device,"orgName":_vm.orgName,"units":_vm.units}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }