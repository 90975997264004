<template>
  <x-form-validator ref="refFormObserver">
    <x-form-feild label="属性标识" require>
      <!--        :state="errors.length > 0 ? false:null"-->
      <b-form-input
        v-model="formData.id"
        :disabled="isEdit"
        type="text"
        placeholder="请输入属性标识"
      />
    </x-form-feild>
    <x-form-feild label="属性名称" require>
      <b-form-input
        v-model="formData.name"
        placeholder="请输入属性名称"
      />
    </x-form-feild>
    <x-form-feild label="数据类型" require>
      <v-select
        v-model="formData.valueType.type"
        :options="$x.datatype.ALL"
        label="label"
        :reduce="option => option.value"
        :get-option-label="option => option.value + ' (' + option.label +')'"
        placeholder="请选择数据类型">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <data-type-form class="w-100" :formData.sync="formData.valueType" :type="type" :units="units"></data-type-form>
    <x-form-feild label="是否只读" require>
      <b-form-radio-group
        v-model="formData.expands.readOnly"
        :options="$x.datatype.boolean"
        value-field="value"
        text-field="label"
      />
    </x-form-feild>
    <x-form-feild label="存储方式" require>
      <v-select
        v-model="formData.expands.storageType"
        :options="storageTypes"
        label="label"
        :reduce="option => option.value"
        placeholder="请选择存储方式">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <x-form-feild label="描述">
      <b-form-textarea
        v-model="formData.description"
        placeholder="请输入描述"
      />
    </x-form-feild>
  </x-form-validator>
</template>

<script>
import { BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import vSelect from 'vue-select'
// import DataTypeForm from './DataTypeForm.vue'

export default {
  components: {
    BFormInput,
    XFormValidator,
    XFormFeild,
    vSelect,
    BFormRadioGroup,
    BFormTextarea,
    // DataTypeForm,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: undefined,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAdd: this.type === 'add',
      isEdit: this.type === 'edit',
      formData: this.dataInTransform(this.value),
    }
  },
  computed: {
    storageTypes() {
      if (!this.formData.valueType.type) {
        return []
      }
      if (this.formData.valueType.type === this.$x.datatype.Array || this.formData.valueType.type === this.$x.datatype.Object) {
        return this.$x.datatype.storageType
      }
      return this.$x.datatype.storageType.filter(item => item.value !== 'json-string')
    },
  },
  watch: {
    value: {
      handler(val) {
        this.formData = this.dataInTransform(val)
      },
      deep: true,
    },
    type: {
      handler(val) {
        this.isAdd = val === 'add'
        this.isEdit = val === 'edit'
      },
      deep: true,
    },
  },
  methods: {
    // 传进来的数据与表单数据转换
    dataInTransform(val) {
      let temp
      if (!val) {
        temp = {}
      } else {
        temp = this._.cloneDeep(val)
      }
      this.$x.datatype.initPropertyData(temp)
      return temp
    },
    getFormData() {
      return this.$x.datatype.getFormData(this.formData)
    },
    validate() {
      return this.$refs.refFormObserver.validate()
    },
    reset() {
      this.$refs.refFormObserver.reset()
      // 防止打开同一行 修改数据后没有保存 sidebar模式下重新刷新组件
      this.formData = this.dataInTransform(this.value)
    },
    scaleShow(type) {
      return type === this.$x.datatype.Float || type === this.$x.datatype.Double
    },
    unitShow(type) {
      return type === this.$x.datatype.Int || type === this.$x.datatype.Long || type === this.$x.datatype.Float || type === this.$x.datatype.Double || type === this.$x.datatype.String
    },
  },
}
</script>

<style lang="scss">
</style>
