<template>
  <div>
    <b-row>
      <!-- card actions -->
      <b-col cols="12">
        <b-card
          ref="cardAction"
          class="p-1"
          no-body
        >
          <b-card-title class="d-flex ">
            <div class="x-indicator mr-50 x-text-bold" />
            <div class="d-flex align-items-center x-text-bold ">
              设备信息
              <feather-icon
                class="ml-4 text-primary x-link"
                icon="Edit3Icon"
                size="18"
                v-b-tooltip.hover.top="'编辑设备信息'"
                @click="showInfoForm = !showInfoForm"
              />
            </div>
          </b-card-title>
          <div>
            <table
              class="align-content-center text-center viewTable"
            >
              <tr>
                <th>
                  产品名称
                </th>
                <td>
                  <b-badge variant="light-primary" class="x-link" @click="toProduct">
                    {{ device.productName }}
                  </b-badge>
                </td>
                <th>
                  节点类型
                </th>
                <td>
                  <b-badge
                    variant="light-primary">
                    {{ device.deviceType.text }}
                  </b-badge>
                </td>
                <th>
                  所属机构
                </th>
                <td>
                  {{ orgName ? orgName : '/' }}
                </td>
              </tr>
              <tr>
                <th>
                  接入协议
                </th>
                <td>
                  <b-badge variant="light-primary">
                    {{ device.protocolName }}
                  </b-badge>
                </td>
                <th>
                  连接协议
                </th>
                <td>
                  <b-badge
                    variant="light-primary">
                    {{ device.transport }}
                  </b-badge>
                </td>
                <th>
                  IP地址
                </th>
                <td>
                  {{ device.address }}
                </td>
              </tr>
              <tr>
                <th>
                  创建时间
                </th>
                <td>
                  {{  device.createTime ? device.createTime.cxFormat() : '/' }}
                </td>
                <th>
                  注册时间
                </th>
                <td>
                  {{ device.registerTime ? device.registerTime.cxFormat() : '/' }}
                </td>
                <th>
                  最后上线时间
                </th>
                <td>
                  {{ device.onlineTime ? device.onlineTime.cxFormat() : '/' }}
                </td>
              </tr>
              <tr>
                <th
                  style="width: 16.5px"
                >
                  说明
                </th>
                <td
                  colspan="5"
                  class="pt-50 pb-50"
                >
                  {{ device.description }}
                </td>
              </tr>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- card actions  "-->
      <b-col
        cols="12"
      >
        <b-card
          ref="cardAction"
          class="pl-1 pr-1"
          no-body
        >
          <b-card-title class="d-flex x-text-bold">
            <div class="x-indicator mr-50" />
            配置信息
            <feather-icon
              v-b-tooltip.hover.top="'编辑设备配置'"
              class="ml-4 text-primary x-link" icon="SettingsIcon" size="18"
              @click="showConfigForm = !showConfigForm"
            />
            <feather-icon
              id="restore-config"
              v-b-tooltip.hover.top="'恢复默认配置'"
              class="ml-2 text-primary x-link" icon="RefreshCwIcon" size="18"
            />
            <x-popconfirm
              target="restore-config"
              content="确定要恢复默认配置？"
              triggers="click"
              @enter="restoreConfig">
            </x-popconfirm>
          </b-card-title>
          <b-alert v-if="showAlert" variant="warning" class="x-anim-fade-in" show>
            <x-popconfirm
              target="config-alert"
              content="确定要重新应用配置？"
              triggers="click"
              @enter="deploy">
            </x-popconfirm>
            <div class="alert-body">
              <span class="el-icon-warning font-medium-1"></span>
              修改配置后请重新<span id="config-alert" class="ml-50 mr-50 x-link">应用配置</span>才能生效
            </div>
          </b-alert>
          <b-img v-if="!configs || configs.length === 0" center class="mb-3 mt-3" height="80"
                 :src="require('@/@core/assets/images/empty.svg')"/>
          <div v-else>
            <div v-for="(config, index) in configs"
                           :key="index"
          >
            <h5>{{ config.name }}</h5>
            <b-alert
              variant="primary"
              :show="!!config.description "
            >
              <div class="alert-body">

                <span class="el-icon-info font-medium-1 mr-50"></span>
                <!--                <i class="el-icon-info text-primary font-medium-1"></i>-->
                <span>{{ config.description }}</span>
              </div>
            </b-alert>
            <b-row>
              <b-col cols="12">
                <table
                  class="align-content-center text-center pzTable"
                >
                  <tr
                    v-for="(prop, index) in config.properties"
                    :key="index"
                  >
                    <th>
                      <div class="d-flex align-items-center justify-content-center">
                        <span>{{ prop.name }}</span>
                        <i
                          v-b-tooltip.hover.top="prop.description"
                          class="el-icon-question ml-50 text-secondary text-lighten-5"
                        />
                      </div>
                    </th>
                    <td>
                      {{ prop.value }}
                      <b-button
                        v-if="prop.value"
                        v-ripple.400="$x.ripple.primary"
                        v-clipboard:copy="prop.value"
                        v-clipboard:success="$x.onCopy"
                        v-clipboard:error="$x.onError"
                        variant="flat-primary"
                        size="sm"
                        class="x-link btn-icon rounded-circle x-transparent">
                        <feather-icon
                          icon="CopyIcon"
                        />
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          ref="cardAction"
          no-body
          class="pl-1 pr-1"
        >
          <b-card-title class="d-flex x-text-bold">
            <div class="x-indicator mr-50" />
            标签信息
            <feather-icon
              v-if="tags && tags.length > 0"
              v-b-tooltip.hover.top="'编辑标签信息'"
              class="ml-4 text-primary x-link" icon="Edit3Icon" size="18"
              @click="showTagForm = !showTagForm"
            />
          </b-card-title>
          <b-img v-if="!tags || tags.length === 0" center class="mb-3 mt-3" height="80"
                 :src="require('@/@core/assets/images/empty.svg')"/>
          <b-row v-else>
            <b-col cols="12">
              <table
                class="align-content-center text-center pzTable"
              >
                <tr
                  v-for="(tag, index) in tags"
                  :key="index"
                >
                  <th>
                    <div class="d-flex align-items-center justify-content-center">
                      <span>{{ tag.name }}</span>
                      <i
                        v-b-tooltip.hover.top="tag.id"
                        class="el-icon-question ml-50 text-secondary text-lighten-5"
                      />
                    </div>
                  </th>
                  <td v-tooltip.hover="getTagValue(tag.id)" >
                    <div class="d-flex align-items-center justify-content-center">
                      <span class="x-text-cut mr-50">{{ getTagValue(tag.id) }}</span>
                      <feather-icon
                        icon="Edit3Icon"
                        class="text-primary x-link"
                        @click="setTagValueHandle(tag)"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-sidebar
      ref="slider"
      :visible="showInfoForm"
      idebar-class="sidebar-lg"
      bg-variant="white"
      right
      backdrop
      shadow
      width="40%"
      @hidden="hideInfoForm"
    >
      <device-form
        v-if="showInfoForm"
        ref="form"
        :is-new="false"
        :device="device"
        @editDone="editDown"
      />
    </b-sidebar>
    <b-sidebar
      ref="slider"
      :visible="showConfigForm"
      idebar-class="sidebar-lg"
      bg-variant="white"
      right
      backdrop
      shadow
      width="40%"
      @hidden="hideConfigForm"
    >
      <device-config-form
        v-if="showConfigForm"
        :deviceId="device.id"
        :data="device.configuration"
        :configs="configs"
        @editDone="editConfigDone"
      />
    </b-sidebar>

    <b-modal
      :visible="showTagForm"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="保存"
      ok-only
      title="编辑标签"
      @hidden="showTagForm = false"
      centered>
      <template slot="modal-footer">
        <span class="mt-1"></span>
      </template>
      <b-row cols="12" v-for="(t,index) in tags" :key="index" class="mt-1">
        <b-col cols="3" class="d-flex align-items-center justify-content-end">
          <span>{{ t.id }}</span>
          <i
            v-b-tooltip.hover.top="t.name"
            class="el-icon-question ml-50 text-secondary text-lighten-5"></i>
        </b-col>
        <b-col cols="8">
          <b-input-group>
            <b-form-input
              :value="getTagValue(t.id)"
              disabled
              placeholder="请输入标签值"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="Edit3Icon"
                class="text-primary x-link"
                @click="setTagValueHandle(t)"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-modal>
    <property-value-form
      v-if="showSpecTagForm"
      :show.sync="showSpecTagForm"
      :propertys="[editTag]"
      :values="editTagValue"
      :is-require="false"
      title="编辑"
      @enter="editTagDone">
    </property-value-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BSidebar, BAlert, VBTooltip, BBadge, BButton, BImg, BModal,
  BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  restoreConfig, editTags, // deleteTag,
} from '@/api/device-manage/device-list'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import DeviceForm from './DeviceForm.vue'
import DeviceConfigForm from './DeviceConfigForm.vue'
import PropertyValueForm from '../things-model/PropertyValueForm.vue'

export default {
  name: 'DeviceInfo',
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BSidebar,
    BAlert,
    DeviceForm,
    DeviceConfigForm,
    XPopconfirm,
    BBadge,
    BButton,
    BImg,
    BModal,
    PropertyValueForm,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    orgName: {
      type: String,
      default: undefined,
    },
    configs: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    tagsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showInfoForm: false,
      showConfigForm: false,
      showTagForm: false,
      // 指定标签编辑
      showSpecTagForm: false,
      // 待编辑的标签
      editTag: undefined,
      editTagValue: {},
      showAlert: false,
      tagsDataMap: undefined,
    }
  },
  watch: {
    tagsData: {
      handler(val) {
        this.setTagsMap(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toProduct() {
      this.$router.push({
        name: 'ec-product-view',
        params: { id: this.device.productId },
      })
    },
    hideInfoForm() {
      this.showInfoForm = false
    },
    editDown() {
      this.$emit('editDone')
      this.showInfoForm = false
    },
    hideConfigForm() {
      this.showConfigForm = false
    },
    editConfigDone() {
      this.$emit('editDone')
      this.showConfigForm = false
      this.showAlert = true
    },
    setTagValueHandle(tag) {
      this.editTag = tag
      this.editTagValue[tag.id] = this.getTagValue(this.editTag.id)
      this.showSpecTagForm = true
    },
    setTagsMap(val) {
      if (!this.tagsDataMap) {
        this.tagsDataMap = new Map()
      } else {
        this.tagsDataMap.clear()
      }
      if (val) {
        val.forEach(item => {
          this.tagsDataMap.set(item.key, item)
        })
      }
    },
    editTagDone(formData, done) {
      let params = []
      let keys = Object.keys(formData)
      keys.forEach(key => {
        let tag = this.tagsDataMap.get(key)
        if (formData[key] === undefined || formData[key] === null || formData[key] === '') {
          return
        }
        if (tag) {
          tag.value = formData[key]
        } else {
          tag = {
            key,
            name: this.editTag.name,
            value: formData[key],
          }
        }
        params.push(tag)
      })
      // if (params.length === 0) {
      //   this.tagsDataMap.forEach(item => {
      //     deleteTag(this.device.id, item.id)
      //   })
      //   this.$xtoast.success('编辑成功')
      //   done()
      //   this.setTagsMap(params)
      // }
      let remains = []
      this.tagsDataMap.forEach(item => {
        // 删除
        if (params.length === 0) {
          if (this.editTag.id === item.key || !item.value) {
            return
          }
          remains.push(item)
          return
        }
        for (let param of params) {
          if (param.key === item.key || !item.value) {
            return
          }
        }
        remains.push(item)
      })
      params = params.concat(...remains)
      editTags(this.device.id, params).then(() => {
        this.$xtoast.success('编辑成功')
        done()
        this.setTagsMap(params)
      })
    },
    deploy() {
      this.$emit('deploy', () => {
        this.showAlert = false
      })
    },
    restoreConfig() {
      restoreConfig(this.device.id).then(() => {
        this.$xtoast.success('恢复成功')
        this.$emit('editDone')
      })
    },
    getTag(tag) {
      return this.tagsDataMap.get(tag) ? this.tagsDataMap.get(tag) : undefined
    },
    getTagValue(tag) {
      return this.tagsDataMap.get(tag) ? this.tagsDataMap.get(tag).value : ''
    },
  },
}
</script>

<style scoped>

</style>
