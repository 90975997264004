<template>
  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
  >
    <b-card class="mb-2">
      <b-row class="d-flex justify-item-center align-items-center">
        <b-col md="1">
          <feather-icon
            size="30"
            class="text-primary x-link"
            icon="ChevronLeftIcon"
            @click="back()"
          />
        </b-col>
        <b-col
          md="8"
          class="d-flex justify-content-between  align-items-center"
        >
          <span class="x-text-bold font-large-1">{{ device.name }}</span>
        </b-col>
        <b-col md="3">
          <x-popconfirm
            target="reset-things-model"
            content="重置后将使用产品的物模型，是否确认？"
            triggers="click"
            @enter="resetThingsModel">
          </x-popconfirm>
          <b-button
            id="reset-things-model"
            v-ripple.400="$x.ripple.danger"
            v-tooltip.hover="`重置后将使用产品的物模型`"
            variant="danger"
            pill
            class="rounded-pill mr-1"
            size="sm"
          >
            <feather-icon
              icon="RefreshCwIcon"
            />
            <span class="ml-50">重置物模型</span>
          </b-button>
          <b-button
            v-ripple.400="$x.ripple.primary"
            variant="primary"
            pill
            class="rounded-pill"
            size="sm"
            @click="getDeviceProfile"
          >
            <feather-icon
              icon="RefreshCwIcon"
            />
            <span class="ml-50">刷新</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="device.description"
          cols="1"
        />
        <b-col
          v-if="device.description"
          cols="10"
          class="d-flex"
        >
          <b-alert
            variant="none"
            show
          >
            <div class="alert-body text-secondary">
              <feather-icon
                class="mr-50"
                icon="FeatherIcon"
                size="16"
              />
              <span>{{ device.description }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        :class="device.description ? '' : 'mt-2'"
        class="d-flex justify-content-left  align-items-center font-medium-1"
      >
        <b-col cols="1" />
        <b-col md="3">
          <span class="text-muted">设备ID：</span>
          <span class="ml-1 text-body">{{ device.id }}</span>
          <b-button
            v-ripple.400="$x.ripple.primary"
            v-clipboard:copy="device.id"
            v-clipboard:success="$x.onCopy"
            v-clipboard:error="$x.onError"
            size="sm"
            variant="flat-primary"
            class="x-link btn-icon rounded-circle x-transparent"
          >
            <feather-icon
              icon="CopyIcon"
            />
          </b-button>
        </b-col>
        <b-col md="3">
          <span class="text-muted">运行状态：</span>
          <b-badge
            v-if="device.state && device.state.value === $x.biz.DevState.NOT_ACTIVE"
            variant="light-warning"
            class="ml-1"
          >
            未启用
          </b-badge>
          <b-badge
            v-if="device.state && device.state.value === $x.biz.DevState.OFFLINE"
            variant="light-danger"
            class="ml-1"
          >
            离线
          </b-badge>
          <b-badge
            v-if="device.state && device.state.value === $x.biz.DevState.ONLINE"
            variant="light-success"
            class="ml-1"
          >
            在线
          </b-badge>
          <span
            v-if="device.state && device.state.value === $x.biz.DevState.NOT_ACTIVE"
            class="x-link ml-2 text-danger"
            @click="deploy"
          >启用设备</span>
          <span
            v-if="device.state && device.state.value === $x.biz.DevState.ONLINE"
            class="x-link ml-2 text-danger"
            @click="disconnect"
          >断开连接</span>
        </b-col>
        <b-col
          md="4"
          class="d-flex"
        >
          <span class="text-muted">所属产品：</span>
          <span
            class="ml-1 text-primary x-link d-flex align-items-center"
            @click="toProduct"
          >
            {{ device.productName }}
            <feather-icon
              icon="CornerRightUpIcon"
              class="text-primary ml-50"
              size="18"
            />
          </span>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs>
        <b-tab
          active
        >
          <template #title>
            <div class="mb-50">
              <feather-icon icon="HomeIcon" />
              <span>设备信息</span>
            </div>
          </template>
          <device-info
            :device="device"
            :configs="configs"
            :tags="tags"
            :tags-data="tagsData"
            :org-name="orgName"
            @editDone="getDeviceProfile"
            @deploy="editDeploy"
          />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="mb-50">
              <feather-icon
                icon="BoxIcon"
                size="18"
              />
              <span>物模型</span>
            </div>
          </template>
          <things-model
            v-model="device"
            :is-product="false"
            @done="getDeviceProfile"
          />
        </b-tab>
        <b-tab
          v-if="device.state"
          lazy
        >
          <template #title>
            <div class="mb-50">
              <feather-icon
                icon="ActivityIcon"
                size="18"
              />
              <span>运行状态</span>
            </div>
          </template>
          <b-tabs>
            <b-tab
              active
              title="总览"
            >
              <div>
                <b-row class="mt-2 ">
                  <b-col
                    lg="3"
                    sm="6"
                  >
                    <b-card
                      no-body
                      class="yxztCard"
                    >
                      <b-card-body class="pb-2 ml-2 d-flex flex-column justify-content-between">
                        <div class="d-flex justify-content-between">
                          <div class="truncate">
                            <span class="text-secondary">设备状态</span>
                            <h1 class=" font-weight-bolder mt-50 ">
                              {{ device.state.text }}
                            </h1>
                          </div>
                          <span
                            class="font-large-1"
                            style="margin-top: -1rem"
                            :class="device.state.value === $x.biz.DevState.OFFLINE ? 'text-danger' : (device.state.value === $x.biz.DevState.ONLINE ? 'text-success' : 'text-warning')"
                          >●</span>
                        </div>
                        <div
                          v-if="device.state.value === $x.biz.DevState.OFFLINE"
                          class="text-secondary"
                        >
                          <span>离线时间：</span>
                          <span>{{ device.offlineTime | formatDate }}</span>
                        </div>
                        <div
                          v-else-if="device.state.value === $x.biz.DevState.ONLINE"
                          class="text-secondary"
                        >
                          <span>在线时间：</span>
                          <span>{{ device.onlineTime | formatDate }}</span>
                        </div>
                        <div
                          v-else-if="device.state.value === $x.biz.DevState.NOT_ACTIVE"
                          class="text-secondary"
                        >
                          <span>创建时间：</span>
                          <span>{{ device.createTime | formatDate }}</span>
                        </div>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <b-col
                    v-for="item in runningStateList"
                    :key="item.index"
                    lg="3"
                    sm="6"
                  >
                    <device-property-running-state
                      :device="device"
                      :property-value="item.value"
                      :property-id="item.id"
                      :property-name="item.name"
                      :property="item.property"
                      :value-list="item.service"
                      @done="getDeviceProfile"
                    />
                  </b-col>
                  <b-col
                    v-for="(item, index) in eventList"
                    :key="index"
                    lg="3"
                    sm="6"
                  >
                    <device-event-running-state
                      :ref="item.id"
                      :device="device"
                      :event="item"
                      :trigger="eventStateList.get(item.id)"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab title="属性数据">
              <b-img
                v-if="!runningStateList || runningStateList.length === 0"
                center
                class="mb-3 mt-3"
                height="80"
                :src="require('@/@core/assets/images/empty.svg')"
              />
              <b-row v-else>
                <b-col
                  v-for="item in runningStateList"
                  :key="item.index"
                  lg="3"
                  sm="6"
                >
                  <device-property-running-state
                    :device="device"
                    :property-value="kFormatter(item.value)"
                    :property="item.property"
                    :value-list="item.service"
                    @done="getDeviceProfile"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="事件上报">
              <b-img
                v-if="!eventList || eventList.length === 0"
                center
                class="mb-3 mt-3"
                height="80"
                :src="require('@/@core/assets/images/empty.svg')"
              />
              <b-row v-else>
                <b-col
                  v-for="(item, index) in eventList"
                  :key="index"
                  lg="3"
                  sm="6"
                >
                  <device-event-running-state
                    :device="device"
                    :event="item"
                    :trigger="eventStateList.get(item.id)"
                  />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="功能调用">
              <b-img
                v-if="!functions || functions.length === 0"
                center
                class="mb-3 mt-3"
                height="80"
                :src="require('@/@core/assets/images/empty.svg')"
              />
              <device-function-call-statistic
                v-else
                :device="device"
                :org-name="orgName"
                :functions="functions"
              />
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="mb-50">
              <feather-icon
                icon="MonitorIcon"
                size="18"
              />
              <span>控制面板</span>
            </div>
          </template>
          <device-ctrl-panel
            :device="device"
            :functions="functions"
          />
        </b-tab>
        <b-tab
          lazy
          disabled
        >
          <template #title>
            <div class="mb-50">
              <feather-icon
                icon="PieChartIcon"
                size="18"
              />
              <span>数据可视化</span>
            </div>
          </template>
          <device-visualization />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="mb-50">
              <feather-icon
                icon="BellIcon"
                size="18"
              />
              <span>告警管理</span>
            </div>
          </template>
          <alarm-manage
            v-model="device"
            :obj-id="device.id"
            :is-product="false"
          />
        </b-tab>
        <b-tab
          lazy
        >
          <template #title>
            <div class="mb-50">
              <feather-icon
                icon="TrelloIcon"
                size="18"
              />
              <span>日志管理</span>
            </div>
          </template>
          <device-log
            :device="device"
            :org-name="orgName"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BTabs, BTab, BCard, BCardBody, BRow, BCol, BBadge, BAlert, BButton, BOverlay, BImg,
} from 'bootstrap-vue'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import {
  getById, deploy, disconnect, multi, getConfig, resetThingsModel,
} from '@/api/device-manage/device-list'
import {
  formatDate2,
} from '@/api/timeFormatUtils'
import { mapGetters } from 'vuex'
import { getOrganizationById } from '@/api/system/org'
import { kFormatter } from '@core/utils/filter'
import DeviceInfo from './DeviceInfo.vue'
import ThingsModel from '../things-model/index.vue'
import AlarmManage from '../alarm/AlarmManage.vue'
import DevicePropertyRunningState from './DevicePropertyRunningState.vue'
import DeviceEventRunningState from './DeviceEventRunningState.vue'
import DeviceCtrlPanel from './DeviceCtrlPanel.vue'
import DeviceVisualization from './DeviceVisualization.vue'
import DeviceLog from './DeviceLog.vue'
import DeviceFunctionCallStatistic from './DeviceFunctionCallStatistic.vue'

export default {
  filters: {
    formatDate(time) {
      const date = new Date(time)
      return formatDate2(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {
    DeviceFunctionCallStatistic,
    DeviceLog,
    DeviceInfo,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BBadge,
    BAlert,
    DevicePropertyRunningState,
    DeviceCtrlPanel,
    DeviceVisualization,
    DeviceEventRunningState,
    ThingsModel,
    AlarmManage,
    BButton,
    BOverlay,
    BImg,
    XPopconfirm,
  },
  computed: {
    ...mapGetters([
      'mapUnits',
    ]),
  },
  data() {
    return {
      showLoading: false,
      properties: [],
      functions: [],
      orgName: undefined,
      device: {
        id: undefined,
        name: undefined,
        orgId: undefined,
        productId: undefined,
        productName: undefined,
        transport: undefined,
        deviceType: {
          text: undefined,
        },
        describe: undefined,
        configuration: {
          username: undefined,
          password: undefined,
          frpbserver: undefined,
        },
      },
      count: 0,
      runningStateList: [],
      eventStateList: new Map(),
      eventList: [],
      configs: [],
      tags: [],
      tagsData: [],
      isSubscribe: false,
    }
  },
  created() {
    // 获取设备信息
    this.getDeviceProfile()
    this.$x.utils.System.getUnits()
  },
  destroyed() {
    this.$x.ws.unsubscribe('device-detail', this.$x.ws.TYPE.REGISTER)
    this.$x.ws.unsubscribe('device-detail', this.$x.ws.TYPE.UNREGISTER)
    this.$x.ws.unsubscribe('device-detail', this.$x.ws.TYPE.ONLINE)
    this.$x.ws.unsubscribe('device-detail', this.$x.ws.TYPE.OFFLINE)
    this.$x.ws.unsubscribe('device-detail', this.$x.ws.TYPE.PROPERTY_REPORT)
  },
  methods: {
    subscribe() {
      if (this.isSubscribe) {
        return
      }
      this.isSubscribe = true
      let params = {
        productId: this.device.productId,
        deviceId: this.device.id,
      }
      this.$x.ws.subscribe('device-detail', this.$x.ws.TYPE.REGISTER, params, msg => {
        this.device.offlineTime = msg.payload.timestamp.cxFormat()
        this.device.state = {
          value: 'offline',
          text: '离线',
        }
      })
      this.$x.ws.subscribe('device-detail', this.$x.ws.TYPE.UNREGISTER, params, () => {
        this.device.state = {
          value: 'notActive',
          text: '未启用',
        }
      })
      this.$x.ws.subscribe('device-detail', this.$x.ws.TYPE.ONLINE, params, msg => {
        this.device.state = {
          value: 'online',
          text: '在线',
        }
        this.device.onlineTime = msg.payload.timestamp.cxFormat()
      })
      this.$x.ws.subscribe('device-detail', this.$x.ws.TYPE.OFFLINE, params, msg => {
        this.device.state = {
          value: 'offline',
          text: '离线',
        }
        this.device.offlineTime = msg.payload.timestamp.cxFormat()
      })
      // let _this = this
      // this.$x.ws.subscribe('device-detail', this.$x.ws.TYPE.EVENT, { eventId: '*', ...params }, msg => {
      //   console.log(this.$refs)
      //   if (_this.$refs['open-door']) {
      //     _this.$refs.fire_alarm.__update(msg.payload.timestamp)
      //   }
      //   // let event = this.eventStateList.get(msg.payload.event)
      //   // if (event) {
      //   //   event.trigger = msg.payload.timestamp
      //   // } else {
      //   //   this.eventStateList.set(msg.payload.event, {
      //   //     trigger: msg.payload.timestamp,
      //   //   })
      //   // }
      //   // this.$forceUpdate()
      //   console.log(this.eventStateList)
      // })
      this.$x.ws.subscribe('device-detail', this.$x.ws.TYPE.PROPERTY_REPORT, params, msg => {
        if (this.runningStateList && this.runningStateList.length > 0 && msg.payload.properties) {
          Object.keys(msg.payload.properties).forEach(prop => {
            this.runningStateList.forEach(state => {
              if (state.id === prop) {
                if (state.property.valueType.unit && this.mapUnits) {
                  let unit = this.mapUnits[state.property.valueType.unit] ? this.mapUnits[state.property.valueType.unit].symbol : ''
                  state.value = msg.payload.properties[prop] + ' ' + unit
                  // state.value = msg.payload.properties[prop] + ' ' + state.property.valueType.unit
                } else {
                  state.value = msg.payload.properties[prop]
                }
                if (state.property.valueType.type === this.$x.datatype.Boolean) {
                  if ((state.value + '') === state.property.valueType.trueValue && state.property.valueType.trueText) {
                    state.value = state.property.valueType.trueText
                  } else if ((state.value + '') === state.property.valueType.falseValue && state.property.valueType.falseText) {
                    state.value = state.property.valueType.falseText
                  }
                }
                if (state.property.valueType.type === this.$x.datatype.GeoPoint
                  || state.property.valueType.type === this.$x.datatype.Boolean) {
                  return
                }
                state.service[0].data.push([msg.payload.timestamp, state.value])
                if (state.service[0].data.length > 15) {
                  state.service[0].data.shift()
                }
              }
            })
          })
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    resetThingsModel() {
      resetThingsModel(this.$route.params.id).then(() => {
        this.$xtoast.success('操作成功！')
        this.getDeviceProfile()
      })
    },
    getDeviceProfile() {
      this.showLoading = true
      this.runningStateList = []
      getById(this.$route.params.id).then(resp => {
        this.device = resp.data.result
        this.tagsData = this.device.tags
        // 订阅设备信息
        this.subscribe()
        if (this.device.orgId) {
          getOrganizationById(this.device.orgId).then(resp2 => {
            if (resp2.data.result.data && resp2.data.result.data.length > 0) {
              this.orgName = resp2.data.result.data[0].name
            }
          })
        }
        if (resp.data.result.metadata) {
          const { properties, functions, events, tags } = JSON.parse(resp.data.result.metadata)
          if (events && events.length > 0) {
            this.eventList = events
          }
          if (functions && functions.length > 0) {
            this.functions = functions
          }
          if (properties && properties.length > 0) {
            this.properties = properties
          }
          if (tags && tags.length > 0) {
            this.tags = tags
          }
          let runningStates = new Map()
          this.properties.forEach(item => {
            runningStates.set(item.id, {
              id: item.id,
              name: item.name,
              property: item,
              value: '',
              service: [
                {
                  name: item.name,
                  data: [],
                },
              ],
            })
          })
          multi(this.$route.params.id, this.device.productId).then(res => {
            if (res.data.result && res.data.result.length > 0) {
              res.data.result.forEach(obj => {
                const runningState = runningStates.get(obj.data.value.property)
                runningState.value = obj.data.value.formatValue
                if (runningState.property.valueType.type === this.$x.datatype.GeoPoint) {
                  return
                }
                if (obj.data.value.value !== undefined && obj.data.value.value !== null) {
                  runningState.service[0].name = obj.data.value.propertyName
                  if (runningState.property.valueType.type === this.$x.datatype.Boolean) {
                    runningState.service[0].data.push(obj.data.value.value ? [obj.data.timestamp, runningState.property.valueType.trueValue]
                      : [obj.data.timestamp, runningState.property.valueType.falseValue])
                  } else if (runningState.property.valueType.type === this.$x.datatype.Date) {
                    runningState.value = obj.data.value.value
                    runningState.service[0].data.push([obj.data.timestamp, obj.data.value.value])
                  } else if (runningState.property.valueType.type === this.$x.datatype.Array
                    || runningState.property.valueType.type === this.$x.datatype.Object) {
                    runningState.value = JSON.stringify(obj.data.value.value)
                  } else {
                    runningState.service[0].data.push([obj.data.timestamp, obj.data.value.value])
                  }
                }
              })
            }
            runningStates.forEach(value => {
              this.runningStateList.push(value)
            })
          })
        }
        const keysArr = Object.keys(this.device.configuration)
        const valuesArr = Object.values(this.device.configuration)
        getConfig(this.$route.params.id).then(res => {
          const configArr = res.data.result
          configArr.forEach(item => {
            this.configs = configArr
            item.properties.forEach(pop => {
              keysArr.forEach((key, index) => {
                if (key === pop.property) {
                  pop.value = valuesArr[index]
                }
              })
            })
          })
        })
        this.showLoading = false
      })
    },
    toProduct() {
      this.$router.push({
        name: 'ec-product-view',
        params: { id: this.device.productId },
      })
    },
    // 设备启用
    deploy() {
      this.showLoading = true
      deploy(this.device.id).then(() => {
        this.$xtoast.success('设备启用成功')
        this.getDeviceProfile()
      })
    },
    editDeploy(done) {
      deploy(this.device.id).then(() => {
        this.$xtoast.success('应用成功')
        done()
      })
    },
    // 断开设备
    disconnect() {
      this.showLoading = true
      disconnect(this.device.id).then(() => {
        this.$xtoast.success('断开成功')
        this.getDeviceProfile()
      })
    },
    kFormatter,
  },
}
</script>
<style>
  .tablecate{
    width: 500px;
  }
  .tabletime{
    width: 500px;
  }
  .tableAction{
    width: 180px;
  }
</style>
