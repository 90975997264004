<template>
  <b-tabs
    vertical
    nav-wrapper-class="nav-vertical"
  >
    <b-tab title="告警规则" active>
      <alarm-rule :isProduct="isProduct" :objId="objId"/>
    </b-tab>
    <b-tab title="告警记录" lazy>
      <b-row>
        <b-col cols="12">
          <x-table
            ref="alarmTable"
            :options="options"
            @search="searchHandle"
            @rowDetail="rowDetail"
            @rowSolve="rowSolve">
            <template slot="alarmId" slot-scope="{ row }">
              <div class="badge badge-light-danger">
                {{ row.alarmName }}
              </div>
            </template>
          </x-table>

          <b-modal
            id="modal-view"
            ref="myModal"
            size="lg"
            title-class="x-text-bold"
            body-class="pl-4 pr-4 x-scrollbar"
            cancel-variant="flat-secondary"
            ok-title="确定"
            cancel-title="取消"
            centered
            scrollable
          >
            <alarm-detail v-model="alarmDetail" style="min-height: 55vh"></alarm-detail>
          </b-modal>
          <b-modal
            id="modal-solve"
            ref="solveModal"
            title-class="x-text-bold"
            body-class="pl-4 pr-4"
            cancel-variant="flat-secondary"
            ok-title="处理"
            cancel-title="取消"
            centered
            title="处理警告"
            @ok="solve"
            @hidden="hidden"
          >
            <x-form-validator ref="refFormObserver">
              <x-form-feild label="处理结果" require>
                <b-form-textarea
                  v-model="solveText"
                  placeholder="请输入处理结果"
                />
              </x-form-feild>
            </x-form-validator>
          </b-modal>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  BTabs, BTab, BRow, BCol, BModal, BFormTextarea, VBToggle,
} from 'bootstrap-vue'
import { getAlarmRecordByProductId, getDeviceAlarmRecord, alarmLogSolve } from '@/api/ops/alarm-record'
import XTable from '@core/components/cx/table/XTable.vue'
import AlarmDetail from '../../../ops/alarm/AlarmDetail.vue'
import AlarmRule from './AlarmRule.vue'

export default {
  components: {
    XFormValidator,
    XFormFeild,
    BTabs,
    BTab,
    BRow,
    BCol,
    XTable,
    BModal,
    BFormTextarea,
    AlarmRule,
    AlarmDetail,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否为产品物模型
     */
    isProduct: {
      type: Boolean,
      default: true,
    },
    /**
     * 设备或产品ID
     */
    objId: {
      type: String,
      default: undefined,
    },
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      alarmDetail: {},
      solveText: undefined,
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        actions: [
          { action: 'detail', name: '详情', icon: 'EyeIcon', tip: '详情' },
          { action: 'solve', name: '处理', icon: 'FeatherIcon', tip: '处理' }],
        actionFilter: (action, row) => {
          if (row.state === 'solve') {
            return action === 'detail'
          }
          return action === 'detail' || action === 'solve'
        },
        columns: [{
          label: '设备ID',
          prop: 'deviceId',
          searchShow: false,
          sortable: true,
        }, {
          label: '设备名称',
          prop: 'deviceName',
          linkUrl: '/device-center/device/view/{deviceId}',
          rowShow: this.isProduct,
          searchShow: false,
          sortable: true,
        }, {
          label: '告警名称',
          prop: 'alarmId',
          type: 'select',
          searchShow: true,
          dictUrl: '/device/alarm/product/' + (this.isProduct ? this.$route.params.id : this.value.productId),
          props: {
            label: 'name',
            value: 'id',
          },
          labShow: false,
        }, {
          label: '处理状态',
          type: 'select',
          prop: 'state',
          rowSelect: true,
          dictData: [{ state: '全部', id: undefined }, { state: '未处理', id: 'newer' }, { state: '已处理', id: 'solve' }],
          props: { label: 'state', value: 'id' },
          selectVariant: prop => {
            if (prop === 'solve') {
              return 'success'
            }
            return 'danger'
          },
          searchShow: true,
        }, {
          label: '告警时间',
          prop: 'alarmTime',
          type: 'datetimerange',
          format: 'yyyy-MM-dd hh:mm',
          searchShow: true,
          sortable: true,
        },
        ],
      },
    }
  },
  watch: {
  },
  methods: {
    searchHandle(page, params, done) {
      if (this.isProduct) {
        getAlarmRecordByProductId(this.$route.params.id, page, params).then(resp => {
          done(resp.data.result.data, resp.data.result.total)
        })
      } else {
        getDeviceAlarmRecord(page.pageIndex - 1, page.pageSize, {
          deviceId: this.$route.params.id,
          alarmId: params ? params.alarmId : undefined,
          state: params ? params.state : undefined,
          alarmTime: params ? params.alarmTime : undefined,
        }).then(resp => {
          done(resp.data.result.data, resp.data.result.total)
        })
      }
    },
    rowDetail(data) {
      this.alarmDetail = data
      this.$refs.myModal.show()
    },
    rowSolve(data) {
      this.solveId = data.id
      this.$refs.solveModal.show()
    },
    solve(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.alarmLogSolve()
    },
    hidden() {
      this.solveText = undefined
    },
    alarmLogSolve() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          alarmLogSolve(this.solveId, this.solveText).then(() => {
            this.$nextTick(() => {
              this.solveText = undefined
              this.$refs.solveModal.hide()
              this.$refs.alarmTable.__searchHandle()
            })
            this.solveText = undefined
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
