import request from '@/auth/jwt/useJwt'

/*  获取产品的告警规则  */
export function getAlarmByProductId(id) {
  return request.axiosIns({
    url: `/device/alarm/product/${id}`,
    method: 'GET',
  })
}
// 新增告警设置
export function patchAlarmRule(data) {
  return request.axiosIns({
    url: `/device/alarm/${data.target}/${data.targetId}`,
    method: 'PATCH',
    data,
  })
}

/*  获取设备的告警规则  */
export function getAlarmByDeviceId(id) {
  return request.axiosIns({
    url: `/device/alarm/device/${id}`,
    method: 'GET',
  })
}

// 启用告警规则
export function enableAlarmRule(id) {
  return request.axiosIns({
    url: `/device/alarm/${id}/_start`,
    method: 'POST',
  })
}

// 禁用告警规则
export function disableAlarmRule(id) {
  return request.axiosIns({
    url: `/device/alarm/${id}/_stop`,
    method: 'POST',
  })
}

// 删除告警规则
export function deleteAlarmRule(id) {
  return request.axiosIns({
    url: `/device/alarm/${id}`,
    method: 'DELETE',
  })
}
