<template>
  <div class="w-100 pl-3 pr-3 pt-2">
    <b-img v-if="!functions || functions.length === 0" center class="mb-3 mt-3" height="80"
           :src="require('@/@core/assets/images/empty.svg')"/>
    <b-card v-else class="yxztCard ctrl-panel p-1">
      <div class="d-flex pt-2">
        <div class="d-flex flex-column mr-4 ">
          <div v-for="(item, index) in actions" :key="index" class="pl-1 text-primary d-flex align-items-center mb-2" :class="item.switch && item.focus ? 'action-down' : 'action-up'" @click="actionClick(item)">
            {{ item.name }}
            <span  v-if="item.switch" class="action x-text-bold" :class="item.focus ? 'text-success' : 'text-danger'">{{item.focus ? '开' : '关'}}</span>
            <span v-if="item.tip" v-tooltip.hover="item.tip" class="text-secondary el-icon-info font-medium-3 ml-50"></span>
          </div>
        </div>
        <b-row cols="12" class="w-100">
          <b-col
            v-for="(item, index) in funcs"
            :key="index"
            lg="3"
            md="6"
            sm="12"
            class="mb-4 "
          >
            <div class="d-flex flex-fill justify-content-center">
              <b-overlay
                variant="light"
                class="function"
                :show="item.loading"
                spinner-variant="primary"
                opacity=".75"
                rounded="lg">
                <div class="function rounded-lg d-flex justify-content-center align-items-center" :class="item.focus ? 'click-down' : 'click-up'" @click="itemClick(item)">
                  <span class="action x-text-bold" v-tooltip.hover="item.id" :class="item.focus ? 'text-success' : 'text-secondary'">{{item.name}}</span>
                  <span class="el-icon-s-tools text-right pr-1 setup text-primary x-link" @click.stop="setParams(item)"></span>
                </div>
              </b-overlay>
            </div>
          </b-col>
        </b-row>
      </div>
      <x-table
        v-if="actions[1].focus"
        ref="protocols"
        :card="false"
        :options="options"
        hide-search-bar
        no-page
        small
        sticky-header="25rem"
        class="protocols x-anim-fade-in"
        @search="searchHandle"
        @rowDetail="rowDetail">
        <template slot="type" slot-scope="{ data }">
          <b-badge :variant="data === 1 ? 'light-primary' : 'light-success'">{{ data === 1 ? '发送' : '回复' }}</b-badge>
        </template>
        <template slot="time" slot-scope="{ data, row }">
          <span v-if="row.type === 1" >{{ data }}</span>
          <b-badge v-else variant="light-success">{{ data + '毫秒'}}</b-badge>
        </template>
      </x-table>
    </b-card>
    <property-value-form
      v-if="showSetParamsForm"
      :show.sync="showSetParamsForm"
      title="设置参数"
      :is-require="false"
      :propertys="setFunc.inputs"
      :values="funcInputParams.get(setFunc.id)"
      @enter="setFuncParamsHandle"
    />
    <b-modal
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title=""
      title="协议数据"
      :visible="protocolDataShow"
      centered
      @hidden="protocolDataShow = false"
      hide-footer>
      <json-viewer :value="rowData" :expand-depth=20 sort boxed copyable></json-viewer>
    </b-modal>
  </div>
</template>
<script>
import {
  callFunction,
} from '@/api/device-manage/device-list'
import {
  BRow, BCol, BImg, BCard, BOverlay, BBadge,
} from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
import PropertyValueForm from '../things-model/PropertyValueForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    PropertyValueForm,
    BOverlay,
    XTable,
    BBadge,
  },
  directives: {
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    functions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      /**
       * 是否显示设置参数
       */
      showSetParamsForm: false,
      setFunc: undefined,
      /**
       * 功能输入参数
       */
      funcInputParams: new Map(),
      actions: [
        { name: '清除参数', value: 1, switch: false, focus: false, tip: '清除所有功能输入参数！' },
        { name: '协议调试：', value: 2, switch: true, focus: false, tip: '开启协议调试，显示协议数据！' },
      ],
      funcs: this.functions.map(item => {
        item.focus = false
        return item
      }),
      rowData: undefined,
      protocolDataShow: undefined,
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        rowSelectable: false,
        searchFold: true,
        lableVertical: true,
        // 新增按钮
        addBtn: false,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        // showActions: false,
        actions: [{ action: 'detail', name: '查看', icon: 'EyeIcon', tip: '查看协议载荷数据' }],
        columns: [{
          label: '消息ID',
          labelShow: true,
          prop: 'mid',
          searchShow: false,
          editShow: false,
          addShow: false,
        }, {
          label: '功能标识',
          labelShow: true,
          prop: 'fid',
          searchShow: false,
          editShow: false,
          addShow: false,
        }, {
          label: '消息类型',
          labelShow: true,
          prop: 'type',
          searchShow: false,
          editShow: false,
          addShow: false,
        }, {
          label: '时间',
          labelShow: true,
          prop: 'time',
          searchShow: false,
          editShow: false,
          addShow: false,
        },
        ],
      },
      protocolDataList: [],
      protocolSendDataMap: new Map(),
    }
  },
  created() {
    let params = {
      productId: this.device.productId,
      deviceId: this.device.id,
    }
    let _this = this
    this.$x.ws.subscribe('device-ctrl-panel', this.$x.ws.TYPE.FUNCTION, params, msg => {
      _this.protocolDataList.push({
        mid: msg.payload.messageId,
        fid: msg.payload.functionId,
        type: 1,
        payload: msg.payload,
        time: msg.payload.timestamp.cxFormat(),
      })
      _this.protocolSendDataMap.set(msg.payload.messageId, {
        fid: msg.payload.functionId,
        time: msg.payload.timestamp,
      })
      if (_this.$refs.protocols) {
        _this.$refs.protocols.__searchHandle()
      }
    })
    this.$x.ws.subscribe('device-ctrl-panel', this.$x.ws.TYPE.FUNCTION_REPLY, params, msg => {
      let send = _this.protocolSendDataMap.get(msg.payload.messageId)
      _this.protocolDataList.push({
        mid: msg.payload.messageId,
        fid: send ? send.fid : msg.payload.functionId,
        type: 2,
        payload: msg.payload,
        time: send ? (msg.payload.timestamp - send.time) : -1,
      })
      if (_this.$refs.protocols) {
        _this.$refs.protocols.__searchHandle()
      }
    })
  },
  destroyed() {
    this.$x.ws.unsubscribe('device-ctrl-panel', this.$x.ws.TYPE.FUNCTION)
    this.$x.ws.unsubscribe('device-ctrl-panel', this.$x.ws.TYPE.FUNCTION_REPLY)
  },
  methods: {
    actionClick(action) {
      switch (action.value) {
        case 1:
          action.focus = !action.focus
          this.$forceUpdate()
          setTimeout(() => {
            this.funcInputParams.clear()
            action.focus = !action.focus
            this.$forceUpdate()
          }, 200)
          break
        case 2:
          action.focus = !action.focus
          this.$forceUpdate()
          break
        default:
          break
      }
    },
    itemClick(item) {
      item.loading = true
      this.$forceUpdate()
      callFunction(this.device.id, item.id, this.funcInputParams.get(item.id) || {}).then(() => {
        item.focus = !item.focus
        item.loading = false
        this.$forceUpdate()
      }).catch(() => {
        item.loading = false
        this.$forceUpdate()
      })
    },
    setParams(item) {
      this.setFunc = item
      if (!this.setFunc.inputs || this.setFunc.inputs.length === 0) {
        this.$xtoast.warning('该功能无参数！')
        return
      }
      this.showSetParamsForm = true
    },
    setFuncParamsHandle(formData, done) {
      this.funcInputParams.set(this.setFunc.id, formData)
      done()
    },
    searchHandle(page, params, done) {
      done(this.protocolDataList)
    },
    rowDetail(data) {
      console.log(data)
      this.rowData = data.payload
      this.protocolDataShow = true
    },
  },
}
</script>

<style lang="scss" scoped>
  .ctrl-panel {
    height: auto;
    .action {
      transition: all 0.23s ease 0.1s;
    }
    .action-down {
      width: 140px;
      height: 48px;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      background: #e6edf5;
      box-shadow: inset 5px 5px 10px #c1c7ce,
      inset -5px -5px 10px #ffffff;
    }
    .action-up {
      width: 140px;
      height: 48px;
      border-top-right-radius: 60px;
      border-bottom-right-radius: 60px;
      background: #e6edf5;
      box-shadow:  -12px -12px 24px #c4c9d0,
      12px 12px 24px #e6edf5;
      &:active {
        @extend .action-down
      }
    }
    .function {
      width: 120px;
      height: 120px;
      .setup {
        position: absolute;
        left: 0px;
        bottom: 14px;
        width: 120px;
      }
    }
    .click-down {
      background: #e6edf5;
      box-shadow: inset 12px 12px 29px #c1c7ce,
      inset -12px -12px 29px #ffffff;
    }
    .click-up {
      background: linear-gradient(145deg, #f6feff, #cfd5dd);
      box-shadow:  12px 12px 37px #c1c7ce,
      -12px -12px 37px #e6edf5;
      &:active {
        // @extend .click-down
      }
    }
    .protocols {
      margin-top: -4rem;
      margin-bottom: -3rem;
    }
  }
  .dark-layout .ctrl-panel {
    .action-up {
      background: linear-gradient(145deg, #242437, #1f1f2e);
      box-shadow:  12px 12px 24px #1b1b28,
      -12px -12px 24px #29293e;
    }
    .action-down {
      background: #222233;
      box-shadow: inset 12px 12px 24px #1b1b28,
      inset -12px -12px 24px #29293e;
    }
    .click-down {
      background: #222233;
      box-shadow: inset 12px 12px 24px #1b1b28,
      inset -12px -12px 24px #29293e;
    }
    .click-up {
      background: linear-gradient(145deg, #242437, #1f1f2e);
      box-shadow:  12px 12px 24px #1b1b28,
      -12px -12px 24px #29293e;
    }
  }
</style>
