<template>
  <div>
    <x-table
      ref="table"
      :options="options"
      no-page
      @search="searchHandle"
      @rowAdd="rowAdd"
      @rowEditRE="rowEditRE"
      @rowLog="rowLog"
      @rowEnable="rowEnable"
      @rowDisable="rowDisable"
      @rowDelete="rowDelete"
    />
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="85%"
      title="告警设置"
      :visible="visibleFlag"
      @hidden="sideHide"
    >
      <alarm-rule-form
        v-if="visibleFlag"
        :obj-id="objId"
        :is-product="isProduct"
        :form-type="formType"
        :form-data="formData"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
  </div>
</template>

<script>
import {
  VBToggle, BSidebar,
} from 'bootstrap-vue'
import {
  getAlarmByProductId, getAlarmByDeviceId, enableAlarmRule, disableAlarmRule, deleteAlarmRule, patchAlarmRule,
} from '@/api/ops/alarm-rule'
import XTable from '@core/components/cx/table/XTable.vue'
import AlarmRuleForm from './AlarmRuleForm.vue'

export default {
  components: {
    AlarmRuleForm,
    XTable,
    BSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    /**
     * 是否为产品物模型
     */
    isProduct: {
      type: Boolean,
      default: true,
    },
    /**
     * 设备或产品ID
     */
    objId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      visibleFlag: false,
      formType: undefined,
      formData: this.initFormData(),
      options: {
        exportBtn: false,
        printBtn: false,
        actions: [
          { action: 'editRE', name: '编辑', icon: 'EditIcon', tip: '编辑', customXForm: true },
          { action: 'log', name: '日志', icon: 'BellIcon', tip: '查看告警日志' },
          { action: 'enable', name: '启用', icon: 'PlayIcon', tip: '启用告警规则' },
          { action: 'disable', name: '禁用', icon: 'PowerIcon', tip: '禁用告警规则' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon', tip: '删除告警规则' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'stopped') {
            return action !== 'disable'
          }
          return action !== 'enable' || action === 'delete'
        },
        columns: [{
          label: 'ID',
          labelShow: true,
          tip: '可自定义输入ID，请确保ID唯一，ID为空时由服务端自动生成！',
          prop: 'id',
          editDisable: true,
          searchShow: false,
        }, {
          label: '告警名称',
          prop: 'name',
          searchShow: false,
          sortable: true,
          rules: {
            rule: 'required',
            message: '请输入规则名称',
          },
        }, {
          label: '规则描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          searchShow: false,
        }, {
          label: '运行状态',
          labelShow: true,
          type: 'select',
          prop: 'state',
          searchShow: false,
          addShow: false,
          editShow: false,
          viewShow: false,
          sortable: true,
          rowSelectDot: true,
          dictData: [{ label: '运行中', value: 'running' }, { label: '已禁用', value: 'stopped' }],
          selectVariant: prop => {
            switch (prop.value) {
              case 'running':
                return 'text-success'
              case 'stopped':
                return 'text-danger'
              default:
                return 'text-primary'
            }
          },
        }, {
          label: '创建时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetime',
          format: 'yyyy-MM-dd hh:mm',
          addShow: false,
          editShow: false,
          viewShow: false,
          searchShow: false,
          sortable: true,
        },
        ],
      },
    }
  },
  methods: {
    initFormData() {
      return {
        alarmRule: {
          shakeLimit: {
            enabled: false,
            time: undefined,
            threshold: undefined,
            alarmFirst: undefined,
          },
          triggers: [{
            trigger: undefined,
            type: undefined,
            filters: [],
          }],
          properties: [{
            alias: undefined,
            property: undefined,
          }],
          actions: [{
            executor: undefined,
            configuration: {
              message: {
                inputs: {
                  valueType: {},
                },
              },
              device: {},
              noticeTypes: [],
              configList: [],
              templateList: [],
            },
          }],
        },
      }
    },
    searchHandle(page, params, done) {
      if (this.isProduct) {
        getAlarmByProductId(this.$route.params.id, page).then(resp => {
          done(resp.data.result, resp.data.result.total)
        })
      } else {
        getAlarmByDeviceId(this.$route.params.id, page).then(resp => {
          done(resp.data.result, resp.data.result.total)
        })
      }
    },
    rowAdd(formData, done) {
      this.formData = this.initFormData()
      if (this.isProduct) {
        this.formData.target = 'product'
      } else {
        this.formData.target = 'device'
      }
      this.formData.targetId = this.objId
      this.formData.id = formData.id
      this.formData.name = formData.name
      this.formData.description = formData.description
      patchAlarmRule(this.formData).then(() => {
        done()
      })
    },
    rowEditRE(data) {
      this.formType = this.$x.biz.FormType.EDIT
      this.formData = this._.cloneDeep(data)
      this.visibleFlag = true
    },
    rowLog(data) {
      this.$emit('toAlarmRecord', data.id)
    },
    rowEnable(data, done) {
      enableAlarmRule(data.id).then(() => {
        done()
      })
    },
    rowDisable(data, done) {
      disableAlarmRule(data.id).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      deleteAlarmRule(data.id).then(() => {
        done()
      })
    },
    editSuccess() {
      this.sideHide()
      this.$refs.table.__searchHandle()
    },
    sideHide() {
      this.visibleFlag = false
      this.formData = {
        alarmRule: {
          shakeLimit: {
            enabled: false,
            time: undefined,
            threshold: undefined,
            alarmFirst: undefined,
          },
          triggers: [{
            trigger: undefined,
            type: undefined,
            filters: [],
          }],
          properties: [{
            alias: undefined,
            property: undefined,
          }],
          actions: [{
            executor: undefined,
            configuration: {
              message: {
                inputs: {
                  valueType: {},
                },
              },
              device: {},
              noticeTypes: [],
              configList: [],
              templateList: [],
            },
          }],
        },
        name: undefined,
        target: 'product',
        targetId: undefined,
      }
    },
  },
}
</script>

<style lang="scss">
</style>
