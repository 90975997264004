<template>
  <x-form-validator ref="refFormObserver" v-if="isInitial">
    <x-form-feild label="功能标识" require>
      <!--        :state="errors.length > 0 ? false:null"-->
      <b-form-input
        v-model="formData.id"
        :disabled="isEdit"
        type="text"
        placeholder="请输入功能标识"
      />
    </x-form-feild>
    <x-form-feild label="功能名称" require>
      <b-form-input
        v-model="formData.name"
        placeholder="请输入功能名称"
      />
    </x-form-feild>
    <x-form-feild label="是否异步" require>
      <b-form-radio-group
        v-model="formData.async"
        :options="$x.datatype.boolean"
        value-field="value"
        text-field="label"
      />
    </x-form-feild>
    <x-form-feild v-if="isInitial" label="输入参数" class="x-anim-fade-in">
      <x-form-repeater :data.sync="formData.inputs" class="ml-1" :isFirst="true" >
        <template slot="form-repeater" slot-scope="{row, index}">
          <b-row >
            <b-col md="9" class="d-flex flex-row align-items-center">
              <b-form-input
                v-model="row[index].id"
                size="sm"
                placeholder="请输入参数标识"
              />
              <feather-icon icon="ChevronRightIcon" size="40" class="ml-1 mr-1"/>
              <b-form-input
                size="sm"
                v-model="row[index].name"
                placeholder="请输入参数名称"
              />
            </b-col>
            <b-col md="3" class="d-flex align-items-center justify-content-center pr-50">
              <b-button
                v-ripple.400="$x.ripple.primary"
                variant="flat-primary" size="sm" class="btn-light-primary" pill
                @click="toParamForm(row[index], index)">
                编辑
              </b-button>
            </b-col>
          </b-row>
        </template>
      </x-form-repeater>
    </x-form-feild>
    <params-form v-model="paramFormData" :show.sync="showParamForm" :type="type" :units="units"
                 @enter="paramsEditDone">
    </params-form>
    <x-form-feild label="输出参数">
      <v-select
        v-model="formData.output.type"
        :options="$x.datatype.output"
        label="label"
        :reduce="option => option.value"
        :get-option-label="option => option.value + ' (' + option.label +')'"
        placeholder="请选择输出参数">
        <template slot="no-options">{{$t('noData')}}</template>
      </v-select>
    </x-form-feild>
    <data-type-form class="w-100" :formData.sync="formData.output" :type="type" :units="units"></data-type-form>
    <x-form-feild label="描述">
      <b-form-textarea
        v-model="formData.description"
        placeholder="请输入描述"
      />
    </x-form-feild>
  </x-form-validator>
</template>

<script>
import { BFormInput, BFormRadioGroup, BFormTextarea, BRow, BCol, BButton } from 'bootstrap-vue'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import XFormRepeater from '@/@core/components/cx/table/XFormRepeater.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    XFormValidator,
    XFormFeild,
    BFormRadioGroup,
    BFormTextarea,
    XFormRepeater,
    vSelect,
    BRow,
    BCol,
    BButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: undefined,
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isInitial: false,
      isAdd: this.type === 'add',
      isEdit: this.type === 'edit',
      formData: {},
      // 是否显示对象参数表单
      showParamForm: false,
      // 对象参数 表单数据
      paramFormData: {},
      paramFormDataIndex: -1,
    }
  },
  watch: {
    value: {
      handler(val) {
        this.formData = this.dataInTransform(val)
        this.isInitial = true
      },
      deep: true,
      immediate: true,
    },
    type: {
      handler(val) {
        this.isAdd = val === 'add'
        this.isEdit = val === 'edit'
      },
      deep: true,
    },
  },
  methods: {
    // 传进来的数据与表单数据转换
    dataInTransform(val) {
      let temp
      if (!val) {
        temp = {}
      } else {
        temp = this._.cloneDeep(val)
      }
      if (!temp.inputs) {
        temp.inputs = []
      }
      if (!temp.output) {
        temp.output = {}
      }
      this.$x.datatype.initPropertyValueTypeData(temp.output)
      return temp
    },
    getFormData() {
      let fdata = this._.cloneDeep(this.formData)
      if (fdata.output) {
        fdata.output = this.$x.datatype.getValueTypeData(fdata.output)
      }
      if (fdata.inputs) {
        let inputs = []
        fdata.inputs.forEach(item => {
          inputs.push(this.$x.datatype.getFormData(item))
        })
        fdata.inputs = inputs
      }
      return fdata
    },
    validate() {
      return this.$refs.refFormObserver.validate()
    },
    reset() {
      this.$refs.refFormObserver.reset()
      // 防止打开同一行 修改数据后没有保存 sidebar模式下重新刷新组件
      this.formData = this.dataInTransform(this.value)
    },
    toParamForm(property, index) {
      this.paramFormData = property
      this.paramFormDataIndex = index
      this.showParamForm = true
    },
    paramsEditDone(paramsData) {
      this.formData.inputs[this.paramFormDataIndex] = paramsData
    },
  },
}
</script>

<style lang="scss">
</style>
