<template>
  <x-table
    :options="options"
    @search="searchHandle"
  >
    <template slot="fid" slot-scope="{ row }">
      <b-badge v-tooltip.hover="parseRowData(row, 'functionId')" variant="light-primary" class="x-text-cut">{{ parseRowData(row, 'functionId') }}</b-badge>
    </template>
    <template slot="fname"  slot-scope="{ row }">
      <b-badge v-tooltip.hover="getFunctionName(parseRowData(row, 'functionId'))" variant="light-warning" class="x-text-cut">{{ getFunctionName(parseRowData(row, 'functionId')) }}</b-badge>
    </template>
    <template slot="input" slot-scope="{ row }">
      <div v-tooltip.hover="JSON.stringify(parseRowData(row, 'inputs'))" class="x-text-cut">{{ parseRowData(row, 'inputs') }}</div>
    </template>
    <template slot="output" slot-scope="{ row }">
      <div v-tooltip.hover="JSON.stringify(parseRowData(row, 'outputs'))" class="x-text-cut">{{ parseRowData(row, 'outputs') }}</div>
    </template>
    <template slot="custom-form" slot-scope="{ row }">
      <device-log-detail
        :data="row"
        :device="device"
        :orgName="orgName"
        :units="units"
      />
    </template>
  </x-table>
</template>
<script>
import {
  BBadge,
} from 'bootstrap-vue'
import {
  queryLogByDeviceId,
} from '@/api/device-manage/device-list'
import { queryUnits } from '@/api/device-access/protocol'
import XTable from '@core/components/cx/table/XTable.vue'
import DeviceLogDetail from './DeviceLogDetail.vue'

export default {
  components: {
    BBadge,
    XTable,
    DeviceLogDetail,
  },
  directives: {
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    orgName: {
      type: String,
      default: undefined,
    },
    functions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      logContent: {},
      units: [],
      options: {
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' }],
        collapseExpandAll: true,
        formWidth: 'lg',
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        columns: [{
          label: '功能标识',
          labelShow: true,
          prop: 'fid',
          searchShow: false,
        }, {
          label: '功能名称',
          labelShow: true,
          prop: 'fname',
          searchShow: false,
        }, {
          label: '输入参数',
          labelShow: true,
          prop: 'input',
          searchShow: false,
        }, {
          label: '输出参数',
          labelShow: true,
          prop: 'output',
          searchShow: false,
        }, {
          label: '时间',
          labelShow: true,
          prop: 'createTime',
          type: 'datetimerange',
          sortable: true,
          searchShow: true,
        }],
      },
    }
  },
  created() {
    queryUnits().then(resp => {
      if (!resp.data.result) {
        return
      }
      this.units = resp.data.result
    })
  },
  methods: {
    searchHandle(page, params, done) {
      if (!params) {
        params = {}
      }
      params.type = 'functionInvoke'
      queryLogByDeviceId(page.pageIndex - 1, page.pageSize, this.device.id, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    parseRowData(row, prop) {
      if (!row) {
        return '暂无'
      }
      if (row._content) {
        return row._content[prop] || '暂无'
      }
      let _content = JSON.parse(row.content)
      row._content = _content
      return _content[prop] || '暂无'
    },
    getFunctionName(fid) {
      if (!fid || fid === '暂无') {
        return '暂无'
      }
      for (let f of this.functions) {
        if (f.id === fid) {
          return f.name
        }
      }
      return '暂无'
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
