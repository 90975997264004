import request from '@/auth/jwt/useJwt'

// 查询机构信息
export function getOrganizationById(id) {
  return request.axiosIns({
    url: `/organization/_query?terms%5B0%5D.column=id&terms%5B0%5D.value=${id}`,
    method: 'get',
  })
}
// 查询机构列表
export function getOrganizationTree(URL) {
  return request.axiosIns({
    url: URL,
    method: 'get',
  })
}
