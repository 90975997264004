<template>
  <validation-observer ref="refFormObserver">
    <b-form class="p-2">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label>设备ID:</label>
            <validation-provider
              #default="{ errors }"
              name="设备ID"
              rules="required"
            >
              <b-form-input
                v-model="formData.id"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入设备ID"
                :readonly="!isNew"
              />
              <small class="text-danger" v-if="errors[0]">设备ID不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>设备名称:</label>
            <validation-provider
              #default="{ errors }"
              name="设备名称"
              rules="required"
            >
              <b-form-input
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入设备名称"
              />
              <small class="text-danger" v-if="errors[0]">设备名称不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>所属产品:</label>
            <validation-provider
              #default="{ errors }"
              name="所属产品"
              rules="required"
            >
              <v-select
                v-model="product"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productList"
                placeholder="请选择所属产品"
                label="name"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">所属产品不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>所属机构:</label>
            <validation-provider
              name="所属机构"
            >
              <v-select
                v-model="formData.orgId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="orginList"
                placeholder="请选择所属机构"
                label="name"
                :reduce="option => option.id"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>描述:</label>
            <b-form-textarea
              id="textarea-default"
              v-model="formData.describe"
              placeholder="请输入设备描述, 限制255个字符长度"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-button
            variant="primary"
            type="submit"
            class="w-100"
            @click.prevent="validationForm"
          >
            确定
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import {
  queryOrgin, queryProductList, add, edit,
} from '@/api/device-manage/device-list'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    device: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        id: undefined,
        name: undefined,
        productId: undefined,
        productName: undefined,
        orgId: undefined,
        describe: undefined,
      },
      product: undefined,
      orginList: [],
      productList: [],
      required,
    }
  },
  watch: {
    device(obj) {
      this.cloneDevice(obj)
    },
  },
  created() {
    if (!this.isNew) {
      this.cloneDevice(this.device)
    }
    queryOrgin().then(resp => {
      this.orginList = resp.data.result
    })
    queryProductList().then(resp => {
      this.productList = resp.data.result
    })
  },
  methods: {
    cloneDevice(dev) {
      this.formData = this._.cloneDeep(dev)
      if (this.formData.description) {
        this.formData.describe = this.formData.description
      }
      this.product = {
        id: dev.productId,
        name: dev.productName,
      }
    },
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.formData.productId = this.product.id
          this.formData.productName = this.product.name
          if (this.isNew) {
            add(this.formData).then(resp => {
              this.$xtoast.success('新增设备成功')
              this.$router.push({
                name: 'ec-device-view',
                params: { id: resp.data.result.id },
              })
            })
          } else {
            edit(this.formData).then(() => {
              this.$xtoast.success('设备编辑成功')
              this.$emit('editDone')
              // this.$router.push({
              //   name: 'ec-device-view',
              //   params: { id: this.formData.id },
              // })
            })
          }
        }
      })
    },
    hide() {
      if (this.isNew) {
        this.$refs.refFormObserver.reset()
        this.formData = {
          id: undefined,
          name: undefined,
          productId: undefined,
          productName: undefined,
          orgId: undefined,
          describe: undefined,
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
