<template>
  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
  >
    <b-card no-body class="yxztCard" :actionRefresh="true">
      <b-card-body class="pb-2 d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="x-dot" :class="levelTag"></div>
            <span class="text-secondary">{{ event.name }}</span>
          </div>
          <div class="d-flex">
            <feather-icon
              v-b-tooltip.hover.top="'刷新'"
              icon="RefreshCwIcon" size="16" class="text-primary ml-50 mr-50 x-link" @click="refresh"></feather-icon>
            <feather-icon
              v-b-tooltip.hover.top="'事件详情'"
              icon="TrendingUpIcon" size="16" class="text-primary ml-50 x-link" @click="showHistory = !showHistory"></feather-icon>
          </div>
        </div>
        <h1 class=" font-weight-bolder font-large-2 x-text-cut">
          {{ totalCount + '次' }}
        </h1>
        <div>
          <span>最近一次：</span>
          <span>{{ lastOccurTime }}</span>
        </div>
      </b-card-body>
      <b-modal
        id="history-modal"
        :visible="showHistory"
        size="xl"
        title-class="x-text-bold"
        body-class="x-scrollbar pl-4 pr-4"
        cancel-variant="flat-secondary"
        ok-title=""
        title="历史数据"
        centered
        hide-footer
        @hidden="hideHistoryModal">
        <x-table
          :card="false"
          :options="options"
          @search="searchHandle">
          <template slot='custom-form' slot-scope='{ row }'>
            <json-viewer :value="row" :expand-depth=20 sort boxed copyable></json-viewer>
          </template>
        </x-table>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  getEventCount, getEventHistory,
} from '@/api/device-manage/device-list'
import { BCard, BCardBody, VBTooltip, BOverlay, BModal } from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BOverlay,
    BModal,
    XTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      default: () => {},
    },
    trigger: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showLoading: false,
      showHistory: false,
      // 最近一次发生事件
      lastOccurTime: '',
      // 最近一次发生事件
      totalCount: 0,
      options: {
        hideStyleSwitch: true,
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' }],
        columns: [{
          label: '设备ID',
          prop: 'deviceId',
          searchShow: false,
          sortable: true,
        }, {
          label: '上报时间',
          prop: 'timestamp',
          type: 'datetimerange',
          sortable: true,
        }],
      },
    }
  },
  computed: {
    levelTag() {
      if (!this.event.expands) {
        return ''
      }
      switch (this.event.expands.level) {
        // 普通
        case this.$x.datatype.eventLevels[0].value:
          return 'bg-primary'
        // 警告
        case this.$x.datatype.eventLevels[1].value:
          return 'bg-warning'
        // 严重
        case this.$x.datatype.eventLevels[2].value:
          return 'bg-danger'
        default:
          return ''
      }
    },
  },
  watch: {
    trigger: {
      handler(val) {
        this.totalCount += 1
        this.lastOccurTime = val.cxFormat()
      },
      deep: true,
    },
  },
  created() {
    this.refresh()
  },
  methods: {
    __update(val) {
      this.totalCount += 1
      this.lastOccurTime = val.cxFormat()
    },
    searchHandle(page, params, done) {
      getEventHistory(page.pageIndex - 1, page.pageSize, this.device.id, this.event.id,
        params ? params.timestamp : undefined).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    refresh() {
      this.showLoading = true
      getEventCount(this.device.id, this.event.id).then(resp => {
        this.totalCount = resp.data.result.total
        if (resp.data.result.data && resp.data.result.data.length > 0) {
          this.lastOccurTime = resp.data.result.data[0].createTime.cxFormat()
        }
        this.showLoading = false
      }).catch(() => {
        this.showLoading = false
      })
    },
    hideHistoryModal() {
      this.showHistory = false
    },
  },
}
</script>
<style lang="scss">
</style>
