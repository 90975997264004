<template>
  <div>
  </div>
</template>
<script>

export default {
  components: {
  },
  directives: {
  },
  props: {
    device: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
