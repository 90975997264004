<template>
  <div class="log-info-container mt-1">
    <div class="d-flex flex-row">
      <div class="x-indicator"></div>
      <div class="d-flex flex-row align-items-center">
        <div class="ml-50">设备信息</div>
      </div>
    </div>
    <div class="">
      <b-row class="x-row mt-1">
        <b-col md="2" class="text-center x-col bg-light-secondary ">产品ID</b-col>
        <b-col md="4" class="x-col bg-light-primary x-link x-text-cut" @click="() => $router.push('/device-center/products/view/'+log.headers.productId)">{{log.headers.productId}}</b-col>
        <b-col md="2"  class="text-center x-col bg-light-secondary x-text-cut">产品名称</b-col>
        <b-col md="4" class="x-col bg-light-primary x-link" @click="() => $router.push('/device-center/products/view/'+log.headers.productId)">{{device.productName}}</b-col>
      </b-row>
      <b-row class="x-row log-mt-1">
        <b-col md="2" class="text-center x-col bg-light-secondary ">设备ID</b-col>
        <b-col md="4" class="x-col bg-light-primary x-link x-text-cut">{{ log.deviceId }}</b-col>
        <b-col md="2"  class="text-center x-col bg-light-secondary">设备名称</b-col>
        <b-col md="4" class="x-col bg-light-primary x-link x-text-cut">{{ log.headers.deviceName }}</b-col>
      </b-row>
      <b-row class="x-row log-mt-1">
        <b-col md="2" class="text-center x-col bg-light-secondary ">设备分组</b-col>
        <b-col md="10" class="x-col bg-light-primary x-text-bold x-text-cut">{{ orgName ? orgName : '/' }}</b-col>
      </b-row>
    </div>
    <div class="">
      <div class="d-flex flex-row mt-1">
        <div class="x-indicator"></div>
        <div class="d-flex flex-row align-items-center">
          <div class="ml-50">日志信息</div>
          <feather-icon
            v-b-modal.raw-modal
            v-ripple.400="$x.ripple.primary"
            v-b-tooltip.hover.top="'查看原始数据'"
            icon="BookOpenIcon"
            size="18"
            class="x-link ml-2 text-primary"
          />
        </div>
      </div>
      <b-row class="x-row mt-1 h-100">
        <b-col md="2"  class="text-center x-col bg-light-secondary">日志类型</b-col>
        <b-col md="10" class="x-col bg-light-primary x-text-bold text-center">
          <span class="text-warning">{{ data.type.text }}</span>
        </b-col>
        <b-col md="2"  class="text-center x-col bg-light-secondary log-mt-1">消息Id</b-col>
        <b-col md="4" class="x-col bg-light-primary x-text-bold x-text-cut log-mt-1">{{ data.messageId }}</b-col>
        <b-col md="2"  class="text-center x-col bg-light-secondary log-mt-1">时间</b-col>
        <b-col md="4" class="x-col bg-light-primary x-text-bold log-mt-1">{{ data.timestamp.cxFormat() }}</b-col>
      </b-row>
      <b-row v-if="log.messageType === $x.biz.LogType.READ_PROPERTY && log.properties" class="x-row h-100">
        <b-col md="6" v-for="(item,index) in log.properties" :key="index" class="log-mt-1">
          <b-row>
            <b-col md="4" class="x-col bg-light-secondary d-flex justify-content-center align-items-center">
              读取属性
            </b-col>
            <b-col md="8" class="x-col bg-light-primary x-text-bold d-flex justify-content-between align-items-center">
              <span class="x-text-cut">{{item}}</span>
              <i class="el-icon-question ml-50 text-secondary text-lighten-5 ml-50" v-b-tooltip.hover.top="getPropertyName(metaProperties, item)"></i>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="(log.messageType === $x.biz.LogType.REPORT_PROPERTY
        || log.messageType === $x.biz.LogType.READ_PROPERTY_REPLY)
        && log.properties" class="x-row h-100">
        <b-col v-if="log.messageType === $x.biz.LogType.READ_PROPERTY_REPLY" md="2"  class="text-center x-col bg-light-secondary log-mt-1">结果</b-col>
        <b-col v-if="log.messageType === $x.biz.LogType.READ_PROPERTY_REPLY"  md="10" :class="log.success ? 'bg-light-success' : 'bg-light-danger'" class="x-col text-center x-text-bold log-mt-1">{{ log.success ? '成功' : '失败' }}</b-col>
        <b-col md="6" v-for="(item,index) in metaProperties" :key="index" class="log-mt-1">
          <b-row>
            <b-col md="4" class="x-col bg-light-secondary d-flex justify-content-center align-items-center">
              <span class="x-text-cut">{{item.id}}</span>
              <i class="el-icon-question ml-50 text-secondary text-lighten-5 ml-50" v-b-tooltip.hover.top="item.name"></i>
            </b-col>
            <b-col md="8" class="x-col bg-light-primary x-text-bold d-flex justify-content-between ">
              <span class="x-text-cut">{{ log.properties[item.id] }}</span>
              <span class="text-secondary x-text-normal mr-1 ml-1">{{ getUnitName(item.valueType.unit)}}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="(log.messageType === $x.biz.LogType.WRITE_PROPERTY
        || log.messageType === $x.biz.LogType.WRITE_PROPERTY_REPLY)
        && log.properties" class="x-row h-100">
        <b-col v-if="log.messageType === $x.biz.LogType.WRITE_PROPERTY_REPLY" md="2"  class="text-center x-col bg-light-secondary log-mt-1">结果</b-col>
        <b-col v-if="log.messageType === $x.biz.LogType.WRITE_PROPERTY_REPLY"  md="10" :class="log.success ? 'bg-light-success' : 'bg-light-danger'" class="x-col text-center x-text-bold log-mt-1">{{ log.success ? '成功' : '失败' }}</b-col>
        <el-divider>属性参数</el-divider>
        <b-col md="6" v-for="(item,index) in Object.keys(log.properties)" :key="index" class="log-mt-1">
          <b-row>
            <b-col md="4" class="x-col bg-light-secondary d-flex justify-content-center align-items-center">
              <span class="x-text-cut">{{item}}</span>
              <i class="el-icon-question ml-50 text-secondary text-lighten-5 ml-50" v-b-tooltip.hover.top="getPropertyName(metaProperties, item)"></i>
            </b-col>
            <b-col md="8" class="x-col bg-light-primary x-text-bold d-flex justify-content-between ">
              <span class="x-text-cut">{{ log.properties[item] }}</span>
              <span class="text-secondary x-text-normal mr-1 ml-1">{{ getPropertyUnit(item, log.properties[item])}}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="log.messageType === $x.biz.LogType.EVENT && log.data" class="x-row h-100">
        <b-col md="6" v-for="(item,index) in Object.keys(log.data)" :key="index" class="log-mt-1">
          <b-row>
            <b-col md="4" class="x-col bg-light-secondary d-flex justify-content-center align-items-center">
              <span class="x-text-cut">{{item}}</span>
              <i class="el-icon-question ml-50 text-secondary text-lighten-5 ml-50" v-b-tooltip.hover.top="getEventPropertyName(log.event, item)"></i>
            </b-col>
            <b-col md="8" class="x-col bg-light-primary x-text-bold d-flex justify-content-between ">
              <span class="x-text-cut">{{ log.data[item] }}</span>
              <span class="text-secondary x-text-normal mr-1 ml-1">{{ getEventUnit(log.event, item, log.data[item])}}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="log.messageType === $x.biz.LogType.FUNCTION_INVOKE && log.inputs" class="x-row h-100">
        <b-col md="2" class="text-center x-col bg-light-secondary log-mt-1">功能标识</b-col>
        <b-col md="4" class="x-col bg-light-primary x-text-bold log-mt-1">{{ func.id }}</b-col>
        <b-col md="2"  class="text-center x-col bg-light-secondary log-mt-1">功能名称</b-col>
        <b-col md="4" class="x-col bg-light-primary x-text-bold log-mt-1">{{ func.name }}</b-col>
        <b-col md="6" v-for="(item,index) in log.inputs" :key="index" class="log-mt-1">
          <b-row>
            <b-col md="4" class="x-col bg-light-secondary d-flex justify-content-center align-items-center">
              <span class="x-text-cut">{{item.name}}</span>
              <i class="el-icon-question ml-50 text-secondary text-lighten-5 ml-50" v-b-tooltip.hover.top="getPropertyName(func.inputs, item.name)"></i>
            </b-col>
            <b-col md="8" class="x-col bg-light-primary x-text-bold d-flex justify-content-between ">
              <span class="x-text-cut">{{ item.value }}</span>
              <span class="text-secondary x-text-normal mr-1 ml-1">{{ getFunctionInputParamsUnit(log.functionId, item.name, item.value) }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="log.messageType === $x.biz.LogType.FUNCTION_INVOKE_REPLY" class="x-row h-100">
        <b-col md="2"  class="text-center x-col bg-light-secondary log-mt-1">结果</b-col>
        <b-col md="10" :class="log.success ? 'bg-light-success' : 'bg-light-danger'" class="x-col text-center x-text-bold log-mt-1">{{ log.success ? '成功' : '失败' }}</b-col>
      </b-row>
    </div>
    <b-modal
      id="raw-modal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title=""
      title="原始数据"
      centered
      hide-footer>
      <json-viewer :value="log" :expand-depth=20 sort boxed copyable></json-viewer>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, VBTooltip, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    orgName: {
      type: String,
      default: undefined,
    },
    device: {
      type: Object,
      default: () => {},
    },
    units: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      log: {
        properties: {},
      },
      metaProperties: [],
      metaFunctions: [],
      metaEvents: [],
      eve: {},
    }
  },
  created() {
    if (this.device.metadata) {
      const { properties, functions, events } = JSON.parse(this.device.metadata)
      this.metaProperties = properties
      this.metaFunctions = functions
      this.metaEvents = events
    }
    if (this.data) {
      this.log = JSON.parse(this.data.content)
      switch (this.log.messageType) {
        case this.$x.biz.LogType.EVENT:
          break
        case this.$x.biz.LogType.REPORT_PROPERTY:
          break
        case this.$x.biz.LogType.READ_PROPERTY:
          break
        case this.$x.biz.LogType.WRITE_PROPERTY:
          break
        case this.$x.biz.LogType.WRITE_PROPERTY_REPLY:
          break
        case this.$x.biz.LogType.CHILD:
          break
        case this.$x.biz.LogType.CHILD_REPLY:
          break
        case this.$x.biz.LogType.FUNCTION_INVOKE:
          this.func = this.getFunction(this.log.functionId)
          break
        case this.$x.biz.LogType.FUNCTION_INVOKE_REPLY:
          break
        case this.$x.biz.LogType.METADATA:
          break
        case this.$x.biz.LogType.STATE_CHECK:
          break
        case this.$x.biz.LogType.STATE_CHECK_REPLY:
          break
        case this.$x.biz.LogType.REGISTER:
          break
        case this.$x.biz.LogType.UNREGISTER:
          break
        case this.$x.biz.LogType.OFFLINE:
          break
        case this.$x.biz.LogType.ONLINE:
          break
        case this.$x.biz.LogType.DISCONNECT:
          break
        case this.$x.biz.LogType.DISCONNECT_REPLY:
          break
        case this.$x.biz.LogType.READ_FIRMWARE:
          break
        case this.$x.biz.LogType.READ_FIRMWARE_REPLY:
          break
        case this.$x.biz.LogType.REPORT_FIRMWARE:
          break
        case this.$x.biz.LogType.PULL_FIRMWARE:
          break
        case this.$x.biz.LogType.PULL_FIRMWARE_REPLY:
          break
        case this.$x.biz.LogType.UPGRADE_FIRMWARE:
          break
        case this.$x.biz.LogType.UPGRADE_FIRMWARE_REPLY:
          break
        case this.$x.biz.LogType.UPGRADE_FIRMWARE_PROGRESS:
          break
        case this.$x.biz.LogType.LOG:
          break
        case this.$x.biz.LogType.TAG:
          break
        case this.$x.biz.LogType.ACKNOWLEDGE:
          break
        case this.$x.biz.LogType.OTHER:
        default:
          break
      }
    }
  },
  methods: {
    getUnitName(unit) {
      if (!unit) {
        return ''
      }
      for (let u of this.units) {
        if (u.value === unit) {
          return u.text || u.name
        }
      }
      return unit
    },
    getProperty(props, id) {
      if (!id) {
        return undefined
      }
      for (let porp of props) {
        if (porp.id === id) {
          return porp
        }
      }
      return undefined
    },
    getFunction(id) {
      return this.getProperty(this.metaFunctions, id)
    },
    getEvent(id) {
      return this.getProperty(this.metaEvents, id)
    },
    getPropertyName(props, id) {
      let input = this.getProperty(props, id)
      if (!input) {
        return undefined
      }
      return input.name
    },
    getPropUnit(input, id, value) {
      if (!input) {
        return undefined
      }
      if (input.unit) {
        return input.unit
      }
      if (input.valueType.unit) {
        return input.valueType.unit
      }
      if (input.valueType.type === this.$x.datatype.Boolean) {
        if (value === input.valueType.trueValue) {
          return input.valueType.trueText
        }
        if (value === input.valueType.falseValue) {
          return input.valueType.falseText
        }
      }
      if (input.valueType.type === this.$x.datatype.Enum) {
        if (input.valueType.elements) {
          for (let en of input.valueType.elements) {
            if (en.value === value) {
              return en.text
            }
          }
        }
      }
      if (input.valueType.type === this.$x.datatype.Object) {
        if (input.valueType.properties) {
          for (let prop of input.valueType.properties) {
            if (prop.id === id) {
              return this.getPropUnit(prop, id, value)
            }
          }
        }
      }
      return ''
    },
    getPropName(input, id, isFirst) {
      if (!input) {
        return undefined
      }
      console.log(input)
      if (!isFirst && input.name) {
        return input.name
      }
      if (input.valueType.type === this.$x.datatype.Boolean) {
        if (id === input.valueType.trueValue) {
          return input.valueType.trueText
        }
        if (id === input.valueType.falseValue) {
          return input.valueType.falseText
        }
      }
      if (input.valueType.type === this.$x.datatype.Enum) {
        if (input.valueType.elements) {
          for (let en of input.valueType.elements) {
            if (en.value === id) {
              return en.text
            }
          }
        }
      }
      if (input.valueType.type === this.$x.datatype.Object) {
        if (input.valueType.properties) {
          for (let prop of input.valueType.properties) {
            if (prop.id === id) {
              return this.getPropName(prop, id, false)
            }
          }
        }
      }
      return ''
    },
    getEventPropertyName(eid, id) {
      console.log(id)
      let event = this.getEvent(eid)
      return this.getPropName(event, id, true)
    },
    getPropertyUnit(id, value) {
      let prop = this.getProperty(this.metaProperties, id)
      return this.getPropUnit(prop, id, value)
    },
    getFunctionInputParamsUnit(fid, id, value) {
      let func = this.getFunction(fid)
      if (!func || !func.inputs) {
        return ''
      }
      let input = this.getProperty(func.inputs, id)
      return this.getPropUnit(input, id, value)
    },
    getEventUnit(eid, id, value) {
      let eve = this.getEvent(eid)
      return this.getPropUnit(eve, id, value)
    },
  },
}
</script>

<style lang="scss" scoped>
  .log-info-container {
    .log-mt-1 {
      margin-top: 1px;
    }
  }
  .my-awesome-json-theme {
    background: #fff;
    white-space: nowrap;
    color: #525252;
    font-size: 14px;
    font-family: Consolas, Menlo, Courier, monospace;

    .jv-ellipsis {
      color: #999;
      background-color: #eee;
      display: inline-block;
      line-height: 0.9;
      font-size: 0.9em;
      padding: 0px 4px 2px 4px;
      border-radius: 3px;
      vertical-align: 2px;
      cursor: pointer;
      user-select: none;
    }
    .jv-button { color: #49b3ff }
    .jv-key { color: #111111 }
    .jv-item {
      &.jv-array { color: #111111 }
      &.jv-boolean { color: #fc1e70 }
      &.jv-function { color: #067bca }
      &.jv-number { color: #fc1e70 }
      &.jv-number-float { color: #fc1e70 }
      &.jv-number-integer { color: #fc1e70 }
      &.jv-object { color: #111111 }
      &.jv-undefined { color: #e08331 }
      &.jv-string {
        color: #42b983;
        word-break: break-word;
        white-space: normal;
      }
    }
    .jv-code {
      .jv-toggle {
        &:before {
          padding: 0px 2px;
          border-radius: 2px;
        }
        &:hover {
          &:before {
            background: #eee;
          }
        }
      }
    }
  }
</style>
