<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: {
        title: {
          text: '',
        },
        tooltip: {
          show: true,
          trigger: 'item',
          triggerOn: 'mousemove',
          alwaysShowContent: true,
          // axisPointer: {
          //   type: 'cross',
          //   label: {
          //     backgroundColor: '#6a7985',
          //   },
          // },
        },
        dataZoom: [
          {
            type: 'slider', // slider表示有滑动块的，
            show: true,
            xAxisIndex: [0], // 表示x轴折叠
            start: 1, // 数据窗口范围的起始百分比,表示1%
            end: 35, // 数据窗口范围的结束百分比,表示35%坐标
          },
          {
            type: 'inside',
            yAxisIndex: [0], // 表示y轴折叠
            start: 1,
            end: 35,
          },
        ],
        legend: {
          show: true,
        },
        grid: {
          width: '90%',
          left: '5%',
          right: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            splitLine: { show: false },
            data: this.optionData.xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        series: this.optionData.series,
      },
    }
  },
}
</script>
