import request from '@/auth/jwt/useJwt'

/*  获取产品的告警历史记录  */
export function getAlarmRecordByProductId(id, page, params) {
  let index = 1
  let URL = `device/alarm/history/_query?pageSize=${page.pageSize}&pageIndex=${page.pageIndex}&sorts[0].name=alarmTime&sorts[0].order=desc&terms[0].column=productId&terms[0].value=${id}`
  if (params) {
    if (params.alarmId) {
      URL += `&terms[${index}].column=alarmId&terms[${index}].value=${params.alarmId}`
      index++
    }
    if (params.state) {
      URL += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
      index++
    }
    if (params.alarmTime) {
      URL += `&terms[${index}].column=alarmTime&terms[${index}].value=${params.alarmTime.join(',')}`
    }
  }
  return request.axiosIns({
    url: encodeURI(URL),
    method: 'GET',
  })
}

/*  查询设备告警列表  */
// eslint-disable-next-line arrow-body-style
export function getDeviceAlarmRecord(pageIndex, pageSize, params) {
  let requestUrl = `device/alarm/history/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts[0].name=alarmTime&sorts[0].order=desc`
  if (params) {
    let index = 0
    if (params.deviceId !== undefined && params.deviceId !== '') {
      requestUrl += `&terms[${index}].column=deviceId&terms[${index}].value=${params.deviceId}`
      index++
    }
    if (params.state) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
      index++
    }
    if (params.alarmId !== undefined && params.alarmId !== '') {
      requestUrl += `&terms[${index}].column=alarmId&terms[${index}].value=${params.alarmId}`
      index++
    }
    if (params.alarmTime) {
      requestUrl += `&terms[${index}].column=alarmTime$btw&terms[${index}].value=${params.alarmTime[0]},${params.alarmTime[1]}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}

export function queryByParam(pageNo, params) {
  let requestUrl = ''
  if (params[0] !== undefined) {
    requestUrl = `device/alarm/history/_query?pageSize=10&pageIndex=${pageNo}&terms[0].column=deviceId&terms[0].value=${params[0]}&sorts[0].name=alarmTime&sorts[0].order=desc`
  }
  if (params[1] !== undefined) {
    requestUrl = `device/alarm/history/_query?pageSize=10&pageIndex=${pageNo}&terms[0].column=state&terms[0].value=${params[1]}&sorts[0].name=alarmTime&sorts[0].order=desc`
  }
  if (params[0] !== undefined && params[1] !== undefined) {
    requestUrl = `device/alarm/history/_query?pageSize=10&pageIndex=${pageNo}&terms[0].column=deviceId&terms[0].value=${params[0]}&terms[1].column=state&terms[1].value=${params[1]}&sorts[0].name=alarmTime&sorts[0].order=desc`
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 查询设备警告未读数量
export function countByState(state) {
  let requstUrl = 'device/alarm/history/_count'
  if (state) {
    requstUrl += `?terms[0].column=state&terms[0].value=${state}`
  }
  return request.axiosIns({
    url: encodeURI(requstUrl),
    method: 'get',
  })
}

export function alarmLogSolve(id, value) {
  let data = {
    data: value,
  }
  return request.axiosIns({
    url: `device/alarm/history/${id}/_solve`,
    method: 'PUT',
    data,
  })
}
