<template>
  <div>
    <x-el-table
      :card='false'
      :options='options'
      @search='searchHandle'
    >
      <template slot='type' slot-scope='{ data }'>
        <b-badge variant='light-primary'>{{ $x.datatype.outputMap[data] }}</b-badge>
      </template>
      <template slot='formatValue' slot-scope='{ data }'>
        <div class='d-flex align-items-center'>
          <span class='mr-50 x-text-cut'>{{data}}</span>
          <b-button
            v-if='false'
            v-ripple.400='$x.ripple.primary'
            v-clipboard:copy='data'
            v-clipboard:success='$x.onCopy'
            v-clipboard:error='$x.onError'
            size='sm'
            variant='flat-primary'
            class='x-link btn-icon rounded-circle x-transparent'>
            <feather-icon
              icon='CopyIcon'
            />
          </b-button>
        </div>
      </template>
      <template slot='actions'>
        <b-button
          v-if='showChart'
          variant='primary'
          @click='showChartModal = true'>
          <feather-icon
            icon='TrendingUpIcon'
            class='mr-50'
          />
          图表
        </b-button>
        <b-button
          v-if="property.valueType.type === $x.datatype.GeoPoint"
          variant='primary'
          @click='showChartModal = true'>
          <feather-icon
            icon='MapPinIcon'
            class='mr-50'
          />
          历史轨迹
        </b-button>
      </template>
      <template slot='custom-form' slot-scope='{ row }'>
        <json-viewer :value="row ? row.value : ''" :expand-depth=20 sort boxed copyable></json-viewer>
      </template>
    </x-el-table>
    <b-modal
      :visible='showChartModal'
      size='xl'
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title=''
      title='图表分析'
      centered
      hide-footer
      @hidden='showChartModal = false'>
      <vue-apex-charts
        v-if='false'
        type='area'
        height='100'
        width='100%'
        class='m-2'
        :options='chartOptionsComputed'
        :series='chartDatas'
      />
      <app-echart-stacked-area
        v-if='showChartModal && property.valueType.type !== $x.datatype.GeoPoint'
        class="w-100"
        :option-data="optionData"
      ></app-echart-stacked-area>
      <x-map-travel
        v-else-if='showChartModal && property.valueType.type === $x.datatype.GeoPoint'
        :data="optionData.series[0].data"
      ></x-map-travel>
      <div v-else  class='d-flex flex-column mb-3 mt-3 justify-content-center align-items-center'>
        <b-img center class='' height='80' :src="require('@/@core/assets/images/empty.svg')"/>
        <span class='mt-1 text-secondary'>暂无数据</span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { getPropertyHistory } from '@/api/device-manage/device-list'
import VueApexCharts from 'vue-apexcharts'
import { BBadge, BButton, BModal, BImg } from 'bootstrap-vue'
import XElTable from '@core/components/cx/table/XElTable.vue'
import XMapTravel from '@core/components/cx/map/XMapTravel.vue'
import { $themeColors } from '@themeConfig'
import { areaChartOptions } from '@core/components/statistics-cards/chartOptions'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    VueApexCharts,
    XElTable,
    XMapTravel,
    BBadge,
    BButton,
    BModal,
    BImg,
    AppEchartStackedArea,
  },
  directives: {
  },
  props: {
    property: {
      type: Object,
      default: () => {},
    },
    device: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showChartModal: false,
      chartDatas: [],
      optionData: {
        xAxisData: [],
        series: [
          {
            name: this.property.name,
            type: 'line',
            data: [],
            smooth: true,
            // showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(115,103,240, 0.6)',
                }, {
                  offset: 1, color: 'rgba(115,103,240, 0.2)',
                }],
                global: false,
              },
            },
          },
        ],
      },
      options: {
        contentHeight: '55vh',
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' }],
        collapseExpandAll: true,
        formWidth: 'lg',
        addBtn: false,
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        columns: [{
          label: '属性标识',
          labelShow: true,
          prop: 'property',
          searchShow: false,
        }, {
          label: '属性名称',
          labelShow: true,
          prop: 'propertyName',
          searchShow: false,
        }, {
          label: '数据类型',
          labelShow: true,
          prop: 'type',
          searchShow: false,
        }, {
          label: '属性值',
          labelShow: true,
          prop: 'formatValue',
          searchShow: false,
        }, {
          label: '时间',
          labelShow: true,
          prop: 'timestamp',
          type: 'datetimerange',
          sortable: true,
          searchShow: true,
        }],
      },
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(areaChartOptions))
      options.theme.monochrome.color = $themeColors[this.color]
      return options
    },
    showChart() {
      return this.property.valueType.type !== this.$x.datatype.Object
        && this.property.valueType.type !== this.$x.datatype.Array
        && this.property.valueType.type !== this.$x.datatype.File
        && this.property.valueType.type !== this.$x.datatype.GeoPoint
    },
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      getPropertyHistory(page.pageIndex - 1, page.pageSize, this.device.id, this.property.id,
        params ? params.timestamp : undefined).then(resp => {
        if (resp.data.result.data) {
          // this.chartDatas = [{
          //   name: this.property.name,
          //   data: resp.data.result.data.map(item => item.value),
          // }]
          this.optionData.series[0].data = resp.data.result.data.map(item => {
            if (this.property.valueType.type === this.$x.datatype.GeoPoint) {
              const { lat, lon } = item.value
              return { lat, lng: lon, of: 'inner' }
            }
            return item.value
          })
          this.optionData.xAxisData = resp.data.result.data.map(item => item.timestamp.cxFormat())
        }
        done(resp.data.result.data, resp.data.result.total)
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>
